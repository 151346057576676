import React, { useEffect, useState } from 'react';
import * as api from 'utils/api';
import Loading from 'shared/loading';
import Notification from 'shared/notification';
import { adminBaseUrl, atriumBaseUrl } from 'resources';
import SegmentBuilder from '@apptentive-private/reasoning';

const fetch = async (url, setValue, setLoading, setErrors, type) => {
  try {
    const response = await api.get(url);
    const data = await response.json();
    if (data && data.error) {
      setErrors(errors => [...errors, `Failed to fetch ${type}: ${data.error}`]);
      setLoading(false);
    } else if (data) {
      setValue(data);
      setLoading(false);
    }
  } catch (error) {
    setErrors(errors => [...errors, `Failed to fetch ${type}: ${error}`]);
    setLoading(false);
  }
};

const handleSaveCriteria = async ({ criteria, appId, interactionId, setErrors, setLoading, setData }) => {
  if (appId && interactionId && Object.keys(criteria).length > 0) {
    if (window.prompt('To update Criteria please enter the App ID & Interaction ID in the form of: "{appId}/{interactionId}":') === `${appId}/${interactionId}`) {
      setLoading(true);
      try {
        const response = await api.put(`${adminBaseUrl}/apps/${appId}/interactions/${interactionId}`, { criteria });
        const data = await response.json();
        setData(data);
        setLoading(false);
      } catch (error) {
        setErrors(errors => [...errors, `Failed to update: ${error}`]);
        setLoading(false);
      }
    }
  }
};

const fetchAttributeChoices = async ({ id, type }, { setErrors, setLoading, setAttributes }) => {
  try {
    const response = await api.get(`${adminBaseUrl}/apps/${id}/attributes/${type}`);
    const data = await response.json();
    if (data && data.error) {
      setErrors(errors => [...errors, `Failed to fetch Attribute Choices: ${data.error}`]);
      setLoading(false);
    } else if (data) {
      setAttributes((attributes) => ({ ...attributes, [type]: data }));
      setLoading(false);
    }
  } catch (error) {
    setErrors(errors => [...errors, `Failed to fetch Attribute Choices: ${error}`]);
    setLoading(false);
  }
};

const AppInteractionShow = ({ match }) => {
  const [attributes, setAttributes] = useState({});
  const [criteria, setCriteria] = useState({});
  const [loading, setLoading] = useState(true);
  const [targeting, setTargeting] = useState();
  const [events, setEvents] = useState();
  const [app, setApp] = useState();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    document.title = 'Interaction Show';
    fetch(`${adminBaseUrl}/apps/${match.params.id}/interactions/${match.params.interaction_id}`, setData, setLoading, setErrors, 'Interaction');
    if (localStorage.getItem('targeting')) {
      fetch(`${adminBaseUrl}/apps/${match.params.id}/targeting`, setTargeting, setLoading, setErrors, 'Targeting');
      fetch(`${atriumBaseUrl}/apps/${match.params.id}/events/all`, setEvents, setLoading, setErrors, 'Apps');
      fetch(`${adminBaseUrl}/apps/${match.params.id}`, setApp, setLoading, setErrors, 'Apps');
      fetchAttributeChoices({ id: match.params.id, type: 'device' }, { setErrors, setLoading, setAttributes });
      fetchAttributeChoices({ id: match.params.id, type: 'person' }, { setErrors, setLoading, setAttributes });
    }
  }, [match]);

  const fetchAttributes = async ({ rule, query }) => {
    const field = rule.key.replace('/', '.');
    try {
      const response = await api.get(`${adminBaseUrl}/apps/${match.params.id}/attributes/${rule.group}/${field}?query=${query}&limit=10`);
      const attributeData = await response.json();
      if (attributeData && attributeData.error) {
        return attributeData.error;
      }
      if (attributeData) {
        return attributeData.values.map((item) => ({ title: item, value: item }));
      }
    } catch (error) {
      return error;
    }
    return [];
  };

  const fetchEvents = async ({ query }) => {
    try {
      const response = await api.get(`${atriumBaseUrl}/apps/${match.params.id}/events?query=${query}&start_date=0&end_date=${Date.now()}&include_archived=true&limit=999&sort_by=label&sort_direction=desc`);
      const attributeData = await response.json();
      if (attributeData && attributeData.error) {
        return attributeData.error;
      } if (attributeData) {
        return attributeData.items.map(({ label }) => ({ title: label.replace('local#app#', ''), value: label }));
      }
    } catch (error) {
      return error;
    }
    return [];
  };

  return (
    <div className="container">
      <div className="columns">
        <div className="column">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/apps">Apps List</a>
              </li>
              <li>
                <a href={`/apps/${match.params.id}`}>{match.params.id}</a>
              </li>
              <li>
                <a href={`/apps/${match.params.id}/interactions`} aria-current="page">Interactions</a>
              </li>
              <li className="is-active">
                <a href={`/apps/${match.params.id}/interactions/${match.params.interaction_id}}`} aria-current="page">{match.params.interaction_id}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {
        errors.length > 0 &&
        <div className="columns">
          <div className="column errors">
            <Notification type="danger" content={errors} dismiss={() => { setErrors([]); }} />
          </div>
        </div>
      }
      {loading && <Loading text="Loading..." />}
      {
        !loading &&
        <div>
          {
            (localStorage.getItem('targeting') && app && targeting && events && attributes.person && attributes.device) && (
              <div className="columns">
                <div className="column">
                  <SegmentBuilder
                    debug
                    criteria={{ ...data?.context?.criteria || {} }}
                    events={events}
                    interactions={targeting}
                    attributes={attributes}
                    onChange={setCriteria}
                    fetchAttributes={fetchAttributes}
                    fetchEvents={fetchEvents}
                    platform={app.platform}
                    irt
                    random
                    bulkUpload
                    bulkUploadLimit={1000}
                    overallLimit={1000}
                  />
                  <br />
                  <button type="button" className="button is-small" onClick={() => { handleSaveCriteria({ criteria, appId: match.params.id, interactionId: match.params.interaction_id, setErrors, setLoading, setData }); }}>Save Criteria</button>
                  <textarea id="output" style={{ width: '100%', height: '800px', padding: '1.25em', whiteSpace: 'pre', wordWrap: 'normal', fontFamily: 'monospace' }} readOnly value={JSON.stringify(criteria, null, 2)} />
                </div>
              </div>
            )
          }
          <br />
          <div className="columns">
            <div className="column">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">Interaction Details</p>
                </header>
                <div className="card-table">
                  <div className="content">
                    <table className="table is-fullwidth is-striped is-bordered">
                      <tbody>
                        <tr>
                          <td><pre className="interaction-json">{JSON.stringify(data, null, 2)}</pre></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default AppInteractionShow;
