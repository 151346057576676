import React from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'utils/date_format';

const AppSearchResult = ({ item = { live: false } }) => (
  <tr>
    <td>{item.id}</td>
    <td><Link to={`/apps/${item.id}`}>{item.title}</Link></td>
    <td>{item.platform}</td>
    <td>{item.live.toString()}</td>
    <td>{item && item.cached_mau && item.cached_mau.toLocaleString()}</td>
    <td>{item.organization_name}</td>
    <td>{dateFormat(item.created_at)}</td>
  </tr>
);

export default AppSearchResult;
