import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { sortBy, prop } from 'ramda';
import * as api from 'utils/api';
import Loading from 'shared/loading';
import { adminBaseUrl } from 'resources';

class Favorites extends Component {
  constructor(params) {
    super(params);
    this.state = {
      data: {},
      errors: [],
      hasFetched: false,
    };

    this.fetch = this.fetch.bind(this);
    this.removeFavorite = this.removeFavorite.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.fetch();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async fetch() {
    try {
      const response = await api.get(`${adminBaseUrl}/favorites`);
      const data = await response.json();
      if (this.mounted && data && !data.error) {
        this.setState({
          data,
          hasFetched: true,
        });
      } else if (this.mounted && data && data.error) {
        this.setState({
          errors: [`Failed to fetch favorites: ${data.error}`, ...this.state.errors],
          hasFetched: true,
        });
      }
    } catch (error) {
      console.error(error);
      if (this.mounted) {
        this.setState({
          errors: [`Failed to fetch favorites: ${error}`, ...this.state.errors],
          hasFetched: true,
        });
      }
    }
  }

  removeFavorite(key, id) {
    return async (event) => {
      if (event) {
        event.preventDefault();
      }
      try {
        const response = await api.del(`${adminBaseUrl}/favorites?type=${key}&id=${id}`);
        const data = await response.json();
        if (data && !data.error) {
          this.setState({
            data,
            hasFetched: true,
          });
        } else if (data && data.error) {
          this.setState({
            errors: [`Failed to fetch favorites: ${data.error}`, ...this.state.errors],
            hasFetched: true,
          });
        }
      } catch (error) {
        console.error(error);
        this.setState({
          errors: [`Failed to fetch favorites: ${error}`, ...this.state.errors],
          hasFetched: true,
        });
      }
    };
  }

  render() {
    return this.state.hasFetched ? (
      <div className="content">
        {Object.keys(this.state.data).map((key) => (
          <div key={key}>
            <h5>{key}</h5>
            <table className="table is-fullwidth is-striped favorites">
              <tbody>
                {sortBy(prop('name'), this.state.data[key]).map((favorite) => (
                  <tr key={favorite.id}>
                    <td>
                      <Link to={`/${key}/${favorite.id}`}>{favorite.name}</Link>
                    </td>
                    <td>
                      <button
                        className="button is-small is-pulled-right remove-favorite"
                        onClick={this.removeFavorite(key, favorite.id)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    ) : (
      <Loading text="Loading Favorites..." />
    );
  }
}

Favorites.propTypes = {};

Favorites.defaultProps = {};

export default Favorites;
