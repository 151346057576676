import React, { createRef } from 'react';
import onClickOutside from 'react-onclickoutside';
import Mousetrap from 'mousetrap';
import { Icon } from '../shared/icon/icon';
import { bemPrefix } from '../../utils/bem';
import './rollout.scss';

const bem = bemPrefix('rollout-modal');

const INITIAL_STATE = {
    name: '',
    percentage: 0,
    group: '',
    users: '',
};

// eslint-disable-next-line no-mixed-operators
const isInValidPercentages = (value) => value && Number(value) > 100 || Number(value) < 0;

export class RolloutModal extends React.PureComponent {
  // eslint-disable-next-line react/no-unused-state
  state = { ...INITIAL_STATE }

  mousetrap = new Mousetrap();

  backdropRef = createRef();

  componentDidMount() {
    this.mousetrap.bind(['esc'], this.handleClickOutside);
  }

  componentWillUnmount() {
    this.mousetrap.unbind(['esc']);
  }

  handleClickOutside = (event) => {
    if (['keydown', 'keypress'].includes(event.type) && event.key === 'Escape') {
      this.props.cancelHandler();
      return;
    }
    if (this.props.targetEl && this.props.targetEl.contains(event.target)) {
      return;
    }
    this.props.cancelHandler();
  };

  handleBackdropClick = event => {
      const { current } = this.backdropRef;
      if (current && event.target !== current) {
          return;
      }
      this.props.cancelHandler();
  }

  handleChange = (event) => {
      const { name, value } = event.target;
      this.setState({ [name]: value });
  }

  onSubmit = event => {
    event.preventDefault();
    const { name, percentage, group, users } = this.state;
    if (!name) {
      return;
    }

    if (isInValidPercentages(percentage)) {
      return;
    }

    const payload = {
      feature: name,
      percentage: Number(percentage),
      groups: group ? group.split(',') : [],
      members: users ? users.split(',') : [],
    };
    this.props.submitHandler(payload);
  }

  render() {
    const { cancelHandler } = this.props;
    const { name, percentage, group, users } = this.state;
    const isDesabled = !name;
    const isError = isInValidPercentages(percentage);
    const inputs = [
        {
            label: 'Name *',
            type: 'text',
            value: name,
            name: 'name',
          },
        {
          label: 'Percentage',
          type: 'text',
          value: percentage,
          symbol: '%',
          name: 'percentage',
        },
        {
          label: 'Group',
          type: 'text',
          value: group,
          name: 'group',
        },
        {
          label: 'Users',
          type: 'text',
          value: users,
          name: 'users',
        },
      ];
    return (
      <div className={bem()} ref={this.backdropRef} onClick={this.handleBackdropClick}>
        <div className={bem('content')}>
          <div className={bem('form__header')}>
            <span>
              New Feature
            </span>
            <div onClick={cancelHandler}>
              <Icon name="cancel" />
            </div>
          </div>
          <form className={bem('form')} onSubmit={this.onSubmit}>
            {inputs.map(input => (
              <label key={input.label} htmlFor={input.name}>
                <span>{input.label}</span>
                {input.symbol ? (
                  <>
                    <input type={input.type} pattern="[0-9]*" name={input.name} value={input.value} autoComplete="off" onChange={this.handleChange} className={isError ? 'form__input error' : ''} />
                    <span className={bem('form__symbol')}>{input.symbol}</span>
                  </>
            ) : (
              <input type={input.type} name={input.name} value={input.value} autoComplete="off" onChange={this.handleChange} />
            )}
              </label>
            ))}
            <div className={bem('form__footer')}>
              <button className={bem('footer-delete')} type="submit" disabled={isDesabled}>
                Save
              </button>
              <button className={bem('footer-cancel')} onClick={cancelHandler}>
                Cancel
              </button>
            </div>

          </form>
        </div>

      </div>
    );
  }
}

export const RolloutModalContainer = onClickOutside(RolloutModal);
