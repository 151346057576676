import React, { Component } from 'react';
import Loading from 'shared/loading';
import Notification from 'shared/notification';
import * as api from 'utils/api';
import { adminBaseUrl } from '../../resources';

class RegisterUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      success: false,
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissNotifications = this.dismissNotifications.bind(this);
  }

  async handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }

    this.setState({
      errors: [],
      success: false,
    });
    if (!this.email.value) {
      this.setState({
        errors: ['Email is required', ...this.state.errors],
      });
      return;
    }
    if (!this.name.value) {
      this.setState({
        errors: ['Name is required', ...this.state.errors],
      });
      return;
    }

    this.setState({ loading: true });
    let response = {};
    try {
      response = await api.post(`${adminBaseUrl}/users`, {
        name: this.name.value,
        email: this.email.value,
      });
    } catch (error) {
      console.error(error);
    }

    if (response.ok) {
      this.setState({
        loading: false,
        success: true,
      });
    } else {
      let json = {};
      try {
        json = await response.json();
      } catch (error) {
        json.error = error;
        console.error(error);
      }

      this.setState({
        loading: false,
        success: false,
        errors: [].concat(json.error || []),
      });
    }
  }

  dismissNotifications(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      errors: [],
      success: false,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">Register User</p>
              </header>
              <div className="card-content">
                <div className="content">
                  {this.state.errors.length ? (
                    <Notification
                      type="danger"
                      content={this.state.errors}
                      dismiss={this.dismissNotifications}
                    />
                  ) : null}
                  {this.state.success ? (
                    <Notification
                      type="info"
                      content={['Successful signup without login!']}
                      dismiss={this.dismissNotifications}
                    />
                  ) : null}
                  <form
                    acceptCharset="UTF-8"
                    id="register-user"
                    className="register-user"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="field">
                      <label className="label" htmlFor="email">
                        Email
                        <div className="control">
                          <input
                            name="email"
                            type="email"
                            className="input email"
                            placeholder="Email"
                            required
                            ref={input => (this.email = input)}
                          />
                        </div>
                      </label>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor="name">
                        Name
                        <div className="control">
                          <input
                            name="name"
                            type="text"
                            className="input name"
                            placeholder="Name"
                            required
                            ref={input => (this.name = input)}
                          />
                        </div>
                      </label>
                    </div>
                    <div className="field">
                      <div className="control">
                        <button
                          type="submit"
                          className="button is-primary"
                          disabled={this.state.loading}
                        >
                          Register User
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {this.state.loading ? <Loading /> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterUser;
