import React, { Component } from 'react';
import { isEmpty, sortBy, prop, reject, reverse } from 'ramda';
import dateFormat from 'utils/date_format';

class AppInteractionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data.interactions,
      hasFetched: false,
      sortKey: 'id',
      sortReverse: false,
    };

    this.sortByKey = this.sortByKey.bind(this);
  }

  componentDidMount() {
    if (this.props.app && this.props.app.id) {
      this.fetch(this.props.app.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.app && nextProps.app.id && !this.state.hasFetched) {
      this.fetch(nextProps.app.id);
    }
  }

  sortByKey(sortKey) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      let sortReverse = false;
      let data = sortBy(prop(sortKey), this.state.data);
      if (sortKey === this.state.sortKey && this.state.sortReverse === false) {
        sortReverse = true;
        data = reverse(data);
      }
      this.setState({ sortKey, data, sortReverse });
    };
  }

  render() {
    return (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Interactions</p>
            </header>
            <div className="card-table">
              <div className="content">
                <table className="table is-fullwidth is-striped is-bordered">
                  <thead>
                    <tr>
                      <th className="sort-by" onClick={this.sortByKey('id')}>ID</th>
                      <th className="sort-by" onClick={this.sortByKey('type')}>Type</th>
                      <th className="sort-by" onClick={this.sortByKey('name')}>Name</th>
                      <th className="sort-by" onClick={this.sortByKey('title')}>Title</th>
                      <th className="sort-by" onClick={this.sortByKey('updated_at')}>Date Updated</th>
                      <th className="sort-by" onClick={this.sortByKey('active')}>Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reject(isEmpty, this.state.data).map((interaction) => (
                      <tr className="interaction-row" key={interaction.id}>
                        <td><a href={`/apps/${this.props.id}/interactions/${interaction.id}`}>{interaction.id}</a></td>
                        <td>{interaction.type}</td>
                        <td>{interaction.name}</td>
                        <td>{interaction.title}</td>
                        <td>{dateFormat(interaction.updated_at)}</td>
                        <td>{interaction.active.toString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppInteractionsTable;
