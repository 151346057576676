import React, { useState, useRef, useCallback } from 'react';
import {
  GDPR_API_ACTIVITY_TYPE,
  GDPR_API_REQUEST_STATUS,
  GDPR_API_PROCESS_TYPE,
  STATUS_LABEL,
  STATUS_ICON,
  MODAL_TYPE,
} from '../gdpr-types';
import { useGDPRContext } from '../gdpr-context';
import { bemPrefix } from '../../../utils/bem';
import { Icon } from '../../shared/icon/icon';
import { PopoverContainer } from '../../shared/popover/popover';
import { handleDate, getType, getGroup, getIdentities, getActivity } from '../gdpr-helpers';

import './gdpr-request-item.scss';

const bem = bemPrefix('gdpr-status-item');

const getStatus = (status) => (
  <div className={`${bem('table-status-container')} ${status}`}>
    {(status === GDPR_API_REQUEST_STATUS.Completed || status === GDPR_API_REQUEST_STATUS.Failed) && (
      <Icon name={STATUS_ICON[status]} />
    )}
    <span>{STATUS_LABEL[status] || ''}</span>
  </div>
);

const formatText = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex)
    .map(part => {
      if (part.match(urlRegex)) {
        return <a href={part} key={part} target="_blank" rel="noreferrer"> {part} </a>;
      }
      return part;
    });
};

export const GdprStatusItem = ({ request, openModal }) => {
  const { handleDownload } = useGDPRContext();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const openDropDown = () => setIsDropDownOpen(true);

  const closeDropDown = () => setIsDropDownOpen(false);

  const donwloadFile = useCallback((req) => {
    handleDownload(req);
    closeDropDown();
  });

  const handleDelete = useCallback((req) => {
    openModal(req, MODAL_TYPE.delete);
    closeDropDown();
  });

  const handleErasure = useCallback((req) => {
    openModal(req, MODAL_TYPE.erasure);
    closeDropDown();
  });

  const isBlockedDownload = (req) =>
    req.status !== GDPR_API_REQUEST_STATUS.Completed;

  const isActiveErasure = (req) =>
    req.status === GDPR_API_REQUEST_STATUS.Completed && req.activity === GDPR_API_ACTIVITY_TYPE.Search;

  const getDropDownOptions = (req) => {
    const basicOptions = [
      {
        key: `${req.id}-download`,
        icon: 'download',
        className: isBlockedDownload(req)
          ? bem('dropdown-container-list__disabled-option')
          : bem('dropdown-container-list__unabled-option'),
        title: 'DOWNLOAD',
        handler: isBlockedDownload(req) ? () => {} : () => donwloadFile(req),
      },
      {
        key: `${req.id}-stop`,
        icon: 'stop',
        className:
          req.status === GDPR_API_REQUEST_STATUS.InProgress
            ? bem('dropdown-container-list__disabled-option')
            : bem('dropdown-container-list__unabled-option'),
        title: 'REMOVE FROM LIST',
        handler: req.status === GDPR_API_REQUEST_STATUS.InProgress ? () => {} : () => handleDelete(req),
      },
    ];

    return req.process === GDPR_API_PROCESS_TYPE.Delete
      ? [
          ...basicOptions,
          {
            key: `${req.id}-delete`,
            icon: 'delete',
            className: isActiveErasure(req)
              ? bem('dropdown-container-list__unabled-option')
              : bem('dropdown-container-list__disabled-option'),
            title: 'ERASE DATA',
            handler: isActiveErasure(req) ? () => handleErasure(req) : () => {},
          },
        ]
      : basicOptions;
  };

  const descrTitle = request.extensions['apptentive.com'].description || '';
  const actionRef = useRef();
  return (
    <tr className={bem()}>
      <td className={bem('date')}>{handleDate(request.date)}</td>
      <td className={bem('process')}>{getType(request.process)}</td>
      <td className={bem('org')} title={request.org_name}>
        {request.org_name}
      </td>
      <td className={bem('descr')} title={descrTitle}>
        {formatText(descrTitle)}
      </td>
      <td className={bem('group')}>{getGroup(request.identity_type)}</td>
      <td className={bem('id')} title={getIdentities(request.extensions)}>
        {getIdentities(request.extensions)}
      </td>
      <td className={bem('activity')}>{getActivity(request.activity)}</td>
      <td className={bem('status')}>{getStatus(request.status)}</td>
      <td className={bem('creator')}>{request.created_by}</td>
      <td className={bem('actions')}>
        <div className={bem('actions-container')} ref={actionRef}>
          <Icon name="dots" onClick={openDropDown} />
          {isDropDownOpen && actionRef && (
            <PopoverContainer
              targetEl={actionRef.current}
              className={bem('dropdown-container')}
              placement="bottom-start"
              isOpen={isDropDownOpen}
              onClose={closeDropDown}
            >
              <div className={bem('dropdown-container-list')}>
                {getDropDownOptions(request).map(({ key, icon, className, title, handler }) => (
                  <div className={className} onClick={handler} key={key}>
                    <Icon name={icon} />
                    <span>{title}</span>
                  </div>
                ))}
              </div>
            </PopoverContainer>
          )}
        </div>
      </td>
    </tr>
  );
};
