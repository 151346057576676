import Session from 'utils/shared_session';

const MAX_CACHE_AGE = 30000; // 30 seconds

export default class SavedLocation {
  /**
  Checks localStorage for a cached location.
  @param path {string} Location to redirect to; defaults to an empty string.
  @return {string}  Returns the cached path if true, otherwise path.
  */
  static check(path = '') {
    let storedLocation = Session.get('savedLocation');
    if (storedLocation) {
      storedLocation = JSON.parse(storedLocation);
      if (Date.now() - storedLocation.cachedAt < MAX_CACHE_AGE) {
        return storedLocation.path;
      }
    }
    return path;
  }

  /**
  Sets a location in localStorage. Useful for redirecting after a login.
  @param path {string} Location to store.
  */
  static set(path, cachedAt = Date.now()) {
    Session.set('savedLocation',
      JSON.stringify({ path, cachedAt }));
  }

  /**
  Clears the saved location from localStorage.
  @param path {string} Location to store.
  */
  static clear() {
    Session.remove('savedLocation');
  }
}
