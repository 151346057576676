import React from 'react';
import SavedLocation from 'utils/saved_location';
import JWT from 'utils/jwt';
import Lock from 'utils/lock_config';
import * as api from 'utils/api';

class Loading extends React.Component {
  constructor(props) {
    super(props);

    this.authenticated = this.authenticated.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentDidMount() {
    document.title = 'Loading | Apptentive';

    Lock.on('authenticated', this.authenticated);

    if (JWT.valid()) {
      this.authenticated();
      return;
    }

    // If Lock fails to respond or takes more than 5 seconds, we should try to navigate for them.
    setTimeout(this.authenticated, 5 * 1000);
  }

  async authenticated(authResult) {
    if (authResult) {
      JWT.set(authResult.idToken);
      JWT.setNonce(authResult.idTokenPayload.nonce);
    }

    try {
      const response = await api.fetchCurrentUser();
      if (!response || !response.admin) {
        console.error('User is not an admin!', response);
        JWT.clearLocalStorage();
        this.redirect('/login#na');
        return;
      }
      this.redirect(SavedLocation.check('/'));
    } catch (error) {
      console.error(error);
      JWT.clearLocalStorage();
      this.redirect('/login#error');
    }
  }

  redirect(location) {
    window.location.assign(location);
  }

  render() {
    return (
      <div className="loading-wrap">
        <h2>Loading...</h2>
      </div>
    );
  }
}

export default Loading;
