import React from 'react';
import { GDPRContextProvider } from './gdpr-context';
import { GdprSearchForm } from './gdpr-search-form/gdpr-search-form';
import { GdprStatusesList } from './gdpr-requests/gdpr-requests';
import { GdprCard } from './gdpr-card/gdpr-card';
import { bemPrefix } from '../../utils/bem';
import { GdprNotification } from './gdpr-notification/gdpr-notification';

import './gdpr.scss';

const bem = bemPrefix('gdpr-page');

const GdprPage = () => (
  <div className={bem('')}>
    <GdprNotification />
    <GdprCard title="Search">
      <GdprSearchForm />
    </GdprCard>
    <GdprCard title="Requests">
      <GdprStatusesList />
    </GdprCard>
    <div className={bem('info')}>Request list is updated every 60 seconds</div>
  </div>
);

export const GdprPageContainer = () => (
  <GDPRContextProvider>
    <GdprPage />
  </GDPRContextProvider>
);
