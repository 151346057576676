/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '../shared/icon/icon';

import { bemPrefix } from '../../utils/bem';
import { useRolloutContext } from './rollout-context';

import './feature-flag.scss';

const bem = bemPrefix('feature-flag');

const default_settings = {
  percentage: 0,
  members: [],
  groups: [],
  isLoading: false,
};

export const FeatureFlag = ({ feature }) => {
  const { updateFeatureSettings, getFeatureSettings } = useRolloutContext();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState({ ...default_settings, feature });
  const [isOpen, setIsOpen] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  const wrapperRef = useRef(null);
  const spanRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (event.target === spanRef.current) {
        return;
      }
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
        setViewMode(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [wrapperRef.current]);

  const handleViewMode = async () => {
    if (isOpen && !viewMode) {
      return;
    }
    setIsLoading(true);
    const newSettings = await getFeatureSettings(feature);
    setSettings(newSettings);
    setViewMode(prev => !prev);
    setIsOpen(prev => !prev);
    setIsLoading(false);
  };

  const handleGetSettings = async () => {
    setViewMode(false);
    setIsOpen(true);
    console.warn(isLoading);
    console.warn(settings);
    const newSettings = await getFeatureSettings(feature);
    setSettings(newSettings);
    setIsLoading(false);
  };

  const handleUpdSettings = async () => {
    setIsLoading(true);
    setViewMode(false);
    setIsOpen(false);
    await updateFeatureSettings(settings);
    setSettings(settings);
    setIsLoading(false);
  };

  const handleMembers = (value) => setSettings({ ...settings, members: value.split(',') });
  const handleGroups = (value) => setSettings({ ...settings, groups: value.split(',') });
  const handlePercents = (value) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(value)) {
      return;
    }
    if (value > 100 || value < 0) {
      return;
    }
    setSettings({ ...settings, percentage: Number(value) });
  };

  const isDisabled = isOpen && !viewMode;

  const inputs = [
    {
      label: 'Percentage',
      value: settings.percentage,
      handler: handlePercents,
      symbol: '%',
    },
    {
      label: 'Groups',
      value: settings.groups,
      handler: handleGroups,
    },
    {
      label: 'Users',
      value: settings.members,
      handler: handleMembers,
    },
  ];

  return (
    <>
      <div className={bem('')}>
        <div className={bem('body')}>
          <div className={bem('title__container')}>
            <span className={bem('title')} onClick={handleViewMode} ref={spanRef}>{feature}</span>
            <button onClick={handleGetSettings} className="button" disabled={isDisabled}>
              <Icon name="pen" className={isDisabled ? bem('disabled') : ''} />
            </button>
          </div>
          {isOpen && (
          <div className={bem('body__wrapper')} ref={wrapperRef}>
            <div className={bem('body__editBtn')}>
              <button onClick={handleUpdSettings} className="button" disabled={viewMode}>
                <Icon name="disk" />
              </button>
            </div>
            <div className={bem('body__container')}>
              {inputs.map((field) => (
                <div key={`${field.label}-${field.symbol}`} className={bem('body__input')}>
                  {field.label === 'Percentage' && (
                    <>
                      <span className={bem('input__span')}>{field.label}</span>
                      <input onChange={(e) => field.handler(e.target.value)} value={field.value} className={viewMode ? bem('disabled') : ''} disabled={viewMode} />
                      <span className={bem('input__percent')}>{field.symbol}</span>
                    </>
                  )}
                  {field.label === 'Users' && (
                    <>
                      <span className={bem('input__span')}>{field.label}</span>
                      <div className={viewMode ? bem('text-container disabled') : bem('text-container')}>
                        <textarea rows="10" onChange={(e) => field.handler(e.target.value)} value={field.value} className={viewMode ? bem('disabled') : ''} disabled={viewMode} />
                      </div>
                    </>
                  )}
                  {field.label === 'Groups' && (
                    <>
                      <span className={bem('input__span')}>{field.label}</span>
                      <input onChange={(e) => field.handler(e.target.value)} value={field.value} className={viewMode ? bem('disabled') : ''} />
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
          )}
        </div>
      </div>
    </>
  );
};
