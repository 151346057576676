import { get, post, del, fetchCurrentUser } from '../../utils/api';
import { atriumBaseUrl } from '../../resources';

const keysApi = {
  fetchKeys: async () => {
    const response = await get(`${atriumBaseUrl}/keys`);
    return response.json();
  },

  deleteKey: async (fullKeyName) => {
    const response = await del(`${atriumBaseUrl}/keys/${fullKeyName}/revoke`);
    return response.json();
  },

  createKey: async (organizationId, desc, keyType, debug, raw, metric, exp) => {
    const { name } = await fetchCurrentUser();
    const response = await post(`${atriumBaseUrl}/keys/${organizationId}?desc=${desc}&key_type=${keyType}&debug=${debug}&raw=${raw}&metric=${metric}&exp=${exp}&created_by=${name}`);
    return response.json();
  },
};

export default keysApi;
