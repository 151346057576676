import React from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'utils/date_format';

const OrganizationSearchResult = ({ item = {} }) => (
  <tr>
    <td>{item.id}</td>
    <td><Link to={`/organizations/${item.id}`}>{item.name}</Link></td>
    <td>{item && item.mau_count && item.mau_count.toLocaleString()}</td>
    <td>{dateFormat(item.created_at)}</td>
  </tr>
);

export default OrganizationSearchResult;
