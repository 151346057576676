import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as api from 'utils/api';
import Loading from 'shared/loading';
import EntitlementEditForm from 'components/organizations/entitlement_edit_form';
import { adminBaseUrl } from 'resources';

class EntitlementEdit extends Component {
  constructor(params) {
    super(params);
    this.state = {
      data: [],
      hasFetched: false,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    document.title = `Editing Entitlement ${this.props.match.params.entitlement_id}`;
    this.fetch(this.props.match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.match.params.id && !this.state.hasFetched) {
      this.fetch(nextProps.match.params.id);
    }
  }

  async fetch(id) {
    try {
      const response = await api.get(`${adminBaseUrl}/organizations/${id}/entitlements`);
      const data = await response.json();
      if (data && Array.isArray(data)) {
        this.setState({
          data,
          hasFetched: true,
        });
      } else if (data && data.error) {
        this.setState({
          errors: [`Failed to fetch Organization Entitlements: ${data.error}`, ...this.state.errors],
          hasFetched: true,
        });
      }
    } catch (error) {
      this.setState({
        errors: [`Failed to fetch Organization Entitlements: ${error}`, ...this.state.errors],
        hasFetched: true,
      });
    }
  }

  render() {
    return this.state.hasFetched ? (
      <div className="container">
        <div className="columns">
          <div className="column">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={`/organizations/${this.props.match.params.id}`}>Org: {this.props.match.params.id}</Link>
                </li>
                <li className="is-active">
                  <Link to={`/organizations/${this.props.match.params.id}/entitlement/${this.props.match.params.entitlement_id}/edit`} aria-current="page">Editing Entitlement: {this.props.match.params.entitlement_id}</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="column">
            <nav className="breadcrumb has-no-separator is-right">
              <ul>
                <li>
                  <Link to={`/organizations/${this.props.match.params.id}/new-entitlement`}>
                    <button className="button is-small" type="button">New Entitlement</button>
                  </Link>
                </li>
                <li>
                  <Link to={`/organizations/${this.props.match.params.id}/entitlement/${this.props.match.params.entitlement_id}/edit`}>
                    <button className="button is-small" type="button">Edit Entitlement</button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <EntitlementEditForm
          entitlements={this.state.data}
          entitlementId={this.props.match.params.entitlement_id}
          mode="edit"
          orgId={this.props.match.params.id}
        />
      </div>
    ) : <Loading text="Loading Entitlements..." />;
  }
}

export default EntitlementEdit;
