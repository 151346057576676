export const dataTransformTimeseries = (labels, json) => {
  const data = labels.map(label => ({ label, datums: [] }));

  /* istanbul ignore else */
  if (json) {
    if (json.error) {
      console.error('error', `Downstream Web Service Error: ${json.error}`);
      return data;
    }
    Object.keys(json).forEach(key => {
      data.map((labelSeries) => {
        labelSeries.datums.push({
          date: new Date(key).valueOf(),
          y: json[key][labelSeries.label] || 0,
        });

        return labelSeries;
      });
    });
  }

  return data;
};

export default dataTransformTimeseries;
