import React, { Component } from 'react';
import * as api from 'utils/api';
import Loading from 'shared/loading';
import AppInteractionsTable from 'components/apps/app_interactions_table';
import { adminBaseUrl } from 'resources';

class AppInteractions extends Component {
  constructor(params) {
    super(params);
    this.state = {
      data: { interactions: [] },
      errors: [],
      loading: true,
    };

    this.fetch = this.fetch.bind(this);
    this.dismissErrors = this.dismissErrors.bind(this);
  }

  componentDidMount() {
    document.title = 'Interaction Table';
    this.fetch(this.props.match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.match.params.id && !this.state.loading) {
      this.fetch(nextProps.match.params.id);
    }
  }

  async fetch(id) {
    try {
      const response = await api.get(`${adminBaseUrl}/apps/${id}/interactions`);
      const data = await response.json();
      if (data && data.error) {
        this.setState({
          errors: [`Failed to fetch Interactions: ${data.error}`, ...this.state.errors],
          loading: false,
        });
      } else if (data) {
        this.setState({
          data,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        errors: [`Failed to fetch Interactions: ${error}`, ...this.state.errors],
        loading: false,
      });
    }
  }

  dismissErrors(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      errors: [],
    });
  }

  render() {
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/apps">Apps List</a>
                </li>
                <li>
                  <a href={`/apps/${this.props.match.params.id}`}>{this.props.match.params.id}</a>
                </li>
                <li className="is-active">
                  <a href={`/apps/${this.props.match.params.id}/interactions`} aria-current="page">Interactions</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {
          this.state.loading ?
            <Loading text="Loading..." /> :
            <div>
              <AppInteractionsTable
                key={`${this.props.match.params.id}-AppInteractions`}
                data={this.state.data}
                id={this.props.match.params.id}
              />
            </div>
        }
      </div>
    );
  }
}

export default AppInteractions;
