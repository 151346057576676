import React from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'utils/date_format';

const OrganizationDetails = ({ organization }) => (
  <div className="columns">
    <div className="column">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">Organization Details</p>
        </header>
        <div className="card-table">
          <div className="content">
            <table className="table is-fullwidth is-striped is-bordered">
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td>{organization.name}</td>
                </tr>
                <tr>
                  <td>Plan:</td>
                  <td>{organization.plan ? organization.plan.tier : 'No Plan'}</td>
                </tr>
                <tr>
                  <td>Billing Contact:</td>
                  <td>
                    {organization.billing_contact ? (
                      <Link to={`/users/${organization.billing_contact.id}`}>
                        {organization.billing_contact.name || organization.billing_contact.email}
                      </Link>
                    ) : (
                      'No Billing Contact'
                    )}
                  </td>
                </tr>
                <tr>
                  <td>CS Manager:</td>
                  <td>{organization.cs_manager ? organization.cs_manager.email : 'No CS Manager'}</td>
                </tr>
                <tr>
                  <td>Obfuscation Enabled?</td>
                  <td>{String(!!organization.obfuscation_regexp)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div className="column">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">Limits & Activity</p>
        </header>
        <div className="card-table">
          <div className="content">
            <table className="table is-fullwidth is-striped is-bordered">
              <tbody>
                <tr>
                  <td>Monthly Active User Count:</td>
                  <td>{organization.mau_count && organization.mau_count.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>MAU Limit:</td>
                  <td>
                    {organization.plan && organization.plan.mau_limit
                      ? organization.plan.mau_limit.toLocaleString()
                      : 'Unlimited'}
                  </td>
                </tr>
                <tr>
                  <td>Limit Interactions:</td>
                  <td>{`${organization.limit_interactions}`}</td>
                </tr>
                <tr>
                  <td>Total Billable Interactions:</td>
                  <td>
                    {organization.billable_interaction_count
                      ? organization.billable_interaction_count.toLocaleString()
                      : null}
                  </td>
                </tr>
                <tr>
                  <td>Interaction Limit:</td>
                  <td>
                    {organization.plan && organization.plan.interaction_limit
                      ? organization.plan.interaction_limit.toLocaleString()
                      : 'Unlimited'}
                  </td>
                </tr>
                <tr>
                  <td>App Limit:</td>
                  <td>
                    {organization.plan && organization.plan.app_limit
                      ? organization.plan.app_limit.toLocaleString()
                      : 'Unlimited'}
                  </td>
                </tr>
                <tr>
                  <td>Total Collaborators:</td>
                  <td>{organization.collaborator_count ? organization.collaborator_count.toLocaleString() : null}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div className="column">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">Meta</p>
        </header>
        <div className="card-table">
          <div className="content">
            <table className="table is-fullwidth is-striped is-bordered">
              <tbody>
                <tr>
                  <td>Created At:</td>
                  <td>{dateFormat(organization.created_at)}</td>
                </tr>
                <tr>
                  <td>Updated At:</td>
                  <td>{dateFormat(organization.updated_at)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
);

OrganizationDetails.defaultProps = {
  organization: {
    name: '',
    plan: {},
    cs_manager: {},
    billing_contact: {},
    limit_interactions: false,
  },
};

export default OrganizationDetails;
