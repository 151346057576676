import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import validator from 'email-validator';
import { Icon } from '../../shared/icon/icon';
import { bemPrefix } from '../../../utils/bem';
import { PopoverContainer } from '../../shared/popover/popover';
import { useGDPRContext } from '../gdpr-context';
import { GDPR_API_REQUEST_TYPE, GDPR_API_GROUP_TYPE } from '../gdpr-types';
import { BLOCK_SEARCH_FOR_CID_FLAG } from '../gdpr-helpers';

import './gdpr-search-form.scss';

const bem = bemPrefix('gdpr-search-form');

const SEARCH_FIELD_INPUT = {
  OrgID: 'OrgID',
  Identity: 'Identity',
  ReqType: 'ReqType',
  Description: 'Description',
};

const SEARCH_FIELD_OPTION = {
  SelectedIdentity: 'SelectedIdentity',
  SelectedReqType: 'SelectedReqType',
};

const IDENTITY_TYPE = {
  Email: 'Email',
  Cid: 'CID',
  ConvID: 'Conversation ID',
  Initial: 'GROUP',
};

const REQ_TYPE = {
  Find: 'Find',
  Delete: 'Delete',
  Initial: 'PROCESS',
};

const INITIAL_INPUT_STATE = {
  [SEARCH_FIELD_INPUT.OrgID]: '',
  [SEARCH_FIELD_INPUT.Identity]: '',
  [SEARCH_FIELD_INPUT.ReqType]: '',
  [SEARCH_FIELD_INPUT.Description]: '',
};

const INITIAL_OPTIONS_STATE = {
  [SEARCH_FIELD_OPTION.SelectedIdentity]: IDENTITY_TYPE.Initial,
  [SEARCH_FIELD_OPTION.SelectedReqType]: REQ_TYPE.Initial,
};

const INITIAL_DROPDOWNS_STATE = {
  isIdentityDropDown: false,
  isReqTypeDropDown: false,
};

const validateEmails = (emails = []) => {
  let verdict = true;

  emails.forEach((e) => {
    if (!validator.validate(e)) {
      verdict = false;
    }
  });

  return verdict;
};

const validateConvId = (strings) => {
  let verdict = true;
  strings.forEach((str) => {
    const regex = /[a-f, A-f, 0-9]/g;
    const res = str.match(regex);
    if (res.length !== str.length || str.length !== 24) {
      verdict = false;
    }
  });
  return verdict;
};

export const GdprSearchForm = () => {
  const { reqToFindOrDelete } = useGDPRContext();
  const [inputs, setInputs] = useState(INITIAL_INPUT_STATE);
  const [options, setOptions] = useState(INITIAL_OPTIONS_STATE);
  const [dropdowns, setDropdowns] = useState(INITIAL_DROPDOWNS_STATE);
  const [error, setError] = useState('');

  const Identity = useRef();
  const ReqType = useRef();

  useEffect(() => {
    if (options[SEARCH_FIELD_OPTION.SelectedIdentity] === 'ConvID' && inputs[SEARCH_FIELD_INPUT.OrgID]) {
      setInputs({ ...inputs, [SEARCH_FIELD_INPUT.OrgID]: '' });
    }

    if (error) {
      setError('');
    }
  }, [options, inputs]);

  const handleInput = useCallback((type, e) => setInputs({ ...inputs, [type]: e.target.value }), [inputs, error]);

  const inputLength = inputs.Identity.split(',').length;

  const closePopover = useCallback(
    () =>
      setDropdowns({
        isIdentityDropDown: false,
        isReqTypeDropDown: false,
      }),
    []
  );

  const togglePopover = useCallback((type) => {
    if (type === SEARCH_FIELD_INPUT.Identity) {
      setDropdowns({
        ...dropdowns,
        isIdentityDropDown: !dropdowns.isIdentityDropDown,
      });
    } else {
      setDropdowns({
        ...dropdowns,
        isReqTypeDropDown: !dropdowns.isReqTypeDropDown,
      });
    }
  }, [dropdowns]);

  const handleSelect = useCallback((field, option) => setOptions({ ...options, [field]: option }), [options]);

  const handleSubmit = useCallback(() => {
    const inputValue = inputs[SEARCH_FIELD_INPUT.Identity]
      .split(',')
      .filter((item) => item)
      .map((str) => str.replace(/ /g, '').toLowerCase());

      if (inputValue.length > 400) {
        setError(`You have reached max input limit: ${inputValue.length}/400`);
        return;
      }
    const identityValue = [...new Set(inputValue)];
    if (options[SEARCH_FIELD_OPTION.SelectedIdentity] === 'ConvID' && !validateConvId(identityValue)) {
      setError('At least one conversation id has incorrect form');
      return;
    }

    if (options[SEARCH_FIELD_OPTION.SelectedIdentity] === IDENTITY_TYPE.Email && !validateEmails(identityValue)) {
      setError('At least one email has incorrect form');
      return;
    }
    const payload = {
      requestType:
        options[SEARCH_FIELD_OPTION.SelectedReqType] === REQ_TYPE.Find
          ? GDPR_API_REQUEST_TYPE.Access
          : GDPR_API_REQUEST_TYPE.ReqToDelete,
      orgId: inputs[SEARCH_FIELD_INPUT.OrgID],
      identityType: GDPR_API_GROUP_TYPE[options[SEARCH_FIELD_OPTION.SelectedIdentity].toLowerCase()],
      identityValue,
      description: inputs[SEARCH_FIELD_INPUT.Description].toLowerCase(),
    };
    reqToFindOrDelete(payload);
    setInputs(INITIAL_INPUT_STATE);
    setOptions(INITIAL_OPTIONS_STATE);
    setDropdowns(INITIAL_DROPDOWNS_STATE);
  }, [options, inputs]);

  const isActiveBtn =
    (options[SEARCH_FIELD_OPTION.SelectedReqType] === REQ_TYPE.Find ||
      options[SEARCH_FIELD_OPTION.SelectedReqType] === REQ_TYPE.Delete) &&
    options[SEARCH_FIELD_OPTION.SelectedIdentity] !== IDENTITY_TYPE.Initial &&
    inputs[SEARCH_FIELD_INPUT.Identity] &&
    (options[SEARCH_FIELD_OPTION.SelectedIdentity] === 'ConvID' || inputs[SEARCH_FIELD_INPUT.OrgID]);

  const isDisabledInput = options[SEARCH_FIELD_OPTION.SelectedIdentity] === 'ConvID';

  const isInputError = (type) => type === SEARCH_FIELD_INPUT.Identity && error;

  const getPlaceholder = () => {
    if (options[SEARCH_FIELD_OPTION.SelectedIdentity] === 'GROUP') {
      return 'CID, Email or Conversation ID';
    }

    if (options[SEARCH_FIELD_OPTION.SelectedIdentity] === 'ConvID') {
      return 'Conversation ID';
    }

    return options[SEARCH_FIELD_OPTION.SelectedIdentity];
  };

  const optionFields = [
    {
      type: SEARCH_FIELD_INPUT.Identity,
      label: getPlaceholder(),
      hasDropDown: true,
      hasInput: true,
      isDisabled: false,
      inputValue: inputs[SEARCH_FIELD_INPUT.Identity],
      dropdownLabel:
        options[SEARCH_FIELD_OPTION.SelectedIdentity] === 'ConvID'
          ? 'Conversation ID'
          : options[SEARCH_FIELD_OPTION.SelectedIdentity],
      isDropDownOpen: dropdowns.isIdentityDropDown,
      targetRef: Identity,
      dropdownOptions: [
        {
          label: 'Email',
          handler: () => handleSelect(SEARCH_FIELD_OPTION.SelectedIdentity, IDENTITY_TYPE.Email),
        },
        {
          label: 'CID',
          handler: BLOCK_SEARCH_FOR_CID_FLAG
            ? () => {}
            : () => handleSelect(SEARCH_FIELD_OPTION.SelectedIdentity, IDENTITY_TYPE.Cid),
        },
        {
          label: 'Conversation ID',
          handler: () => handleSelect(SEARCH_FIELD_OPTION.SelectedIdentity, 'ConvID'),
        },
      ],
    },
    {
      type: SEARCH_FIELD_INPUT.ReqType,
      label: '',
      hasDropDown: true,
      hasInput: false,
      isDisabled: false,
      inputValue: '',
      dropdownLabel: options[SEARCH_FIELD_OPTION.SelectedReqType],
      isDropDownOpen: dropdowns.isReqTypeDropDown,
      targetRef: ReqType,
      dropdownOptions: [
        {
          label: 'Find',
          handler: () => handleSelect(SEARCH_FIELD_OPTION.SelectedReqType, REQ_TYPE.Find),
        },
        {
          label: 'Delete',
          handler: () => handleSelect(SEARCH_FIELD_OPTION.SelectedReqType, REQ_TYPE.Delete),
        },
      ],
    },
    {
      type: SEARCH_FIELD_INPUT.OrgID,
      label: 'Organization ID',
      hasDropDown: false,
      hasInput: true,
      isDisabled: isDisabledInput,
      inputValue: inputs[SEARCH_FIELD_INPUT.OrgID],
    },
    {
      type: SEARCH_FIELD_INPUT.Description,
      label: 'Description (optional)',
      hasDropDown: false,
      hasInput: true,
      hasDescr: true,
      isDisabled: false,
      inputValue: inputs[SEARCH_FIELD_INPUT.Description],
    },
  ];
  const isDisabledOption = (dropdown) =>
    (BLOCK_SEARCH_FOR_CID_FLAG && dropdown.label === 'CID') ||
    dropdown.label === options[SEARCH_FIELD_OPTION.SelectedIdentity] ||
    (dropdown.label === 'Conversation ID' && options[SEARCH_FIELD_OPTION.SelectedIdentity] === 'ConvID') ||
    dropdown.label === options[SEARCH_FIELD_OPTION.SelectedReqType];

  return (
    <div className={bem('')}>
      <div className={bem('card')}>
        {optionFields.map((option) => (
          <div
            className={classNames(bem('card-field'), {
              [bem('card-field-short')]: !option.hasInput,
              [bem('card-process-dropdown')]: !option.hasInput,
              [bem('card-field-medium')]: !option.hasDropDown,
              [bem('card-field-long')]: option.hasDropDown && option.hasInput,
              [bem('card-field-long')]: option.hasDropDown && option.hasInput,
              [bem('card-field-descr')]: option.hasDescr,
              [bem('card-disabled-field')]: option.isDisabled,
              [bem('card-field-error')]: isInputError(option.type),
            })}
            key={option.type}
          >
            {option.hasInput && (
              <input
                className={bem('card-field-input')}
                value={option.inputValue}
                placeholder={option.label}
                onChange={(e) => handleInput(option.type, e)}
                disabled={option.isDisabled}
                type="text"
              />
            )}
            {option.hasDropDown && (
              <div
                className={bem('card-field-dropdown')}
                ref={option.targetRef}
                onClick={() => togglePopover(option.type)}
              >
                <span>{option.dropdownLabel}</span>
                <Icon name="arrowDown" />
                {option.isDropDownOpen && (
                  <PopoverContainer
                    targetEl={option.targetRef.current}
                    className={bem('dropdown-container')}
                    placement="bottom"
                    isOpen={option.isDropDownOpen}
                    onClose={closePopover}
                  >
                    <div className={bem('dropdown-container-list')}>
                      {option.dropdownOptions.map((dropdown) => (
                        <div
                          className={classNames(bem('dropdown-container-list-item'), {
                            'disabled-option': isDisabledOption(dropdown),
                          })}
                          key={dropdown.label}
                          onClick={dropdown.handler}
                        >
                          {dropdown.label}
                        </div>
                      ))}
                    </div>
                  </PopoverContainer>
                )}
              </div>
            )}
            {isInputError(option.type) && <span>{error}</span>}
          </div>
        ))}
        <button className={bem('card-submit-btn')} onClick={handleSubmit} disabled={!isActiveBtn}>
          GO
        </button>
      </div>
      {inputs.Identity.length > 0 && !error && <span>{`Values count: ${inputLength}/400`}</span> }
    </div>
  );
};
