import React, { createContext, useContext } from 'react';
import FileSaver from 'file-saver';
import { GDPR_SORT_BY_OPTION, GDPR_API_REQUEST_TYPE } from './gdpr-types';
import { post } from '../../utils/api';
import { atriumBaseUrl } from '../../resources';
import gdprApi from './gdpr-service';
import { getBucketFileNameFromUrl, getExportFileName } from './gdpr-helpers';

const INITIAL_STATE = {
  gdprProcessList: [],
  statusesCount: 0,
  isLoading: false,
  shownModal: '',
  notification: {
    message: '',
    type: '',
  },
  sortBy: {
    name: GDPR_SORT_BY_OPTION.Date,
    isAsc: false,
  },
};

const REFRESH_LIST_TIMER = 1000 * 60;
const NOTIFICATION_TIMER = 1000 * 3;

const noop = () => null;

export const GDPRContext = createContext({
  ...INITIAL_STATE,
  reqToFindOrDelete: noop,
  fetchReqList: noop,
});

export class GDPRContextProvider extends React.PureComponent {
  state = { ...INITIAL_STATE };

  refreshTimer = null;

  componentDidMount = () => this.fetchReqList()

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sortBy !== this.state.sortBy) {
      this.fetchReqList();
    }
  }

  componentWillUnmount() {
    this.stopInterval();
  }

  handleDownload = async (request) => {
    const downloadUrl = `${atriumBaseUrl}/insights/apps/export/get-file-url`;
    const { bucketName, fileKey } = getBucketFileNameFromUrl(request.results_url || '');
    const fileName = getExportFileName(request);
    return post(downloadUrl, {
      bucketName,
      fileKey,
    })
      .then((resp) => resp.json())
      .then(({ url }) => new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.open('GET', url, true);
        xhr.onload = () => {
          FileSaver.saveAs(xhr.response, `${fileName}.zip`, true);
          resolve();
        };
        xhr.onerror = () => reject();
        xhr.send();
      }))
      .catch(() => {
        this.showNotification({
          message: 'Some error occurred',
          type: 'error',
        });
      });
  };

  handleSorting = (option) => {
    if (this.state.sortBy.name === option) {
      this.setState({
        ...this.state,
        sortBy: {
          ...this.state.sortBy,
          isAsc: !this.state.sortBy.isAsc,
        },
      });
    } else {
      this.setState({
        ...this.state,
        sortBy: { name: option, isAsc: false },
      });
    }
  };

  startNewInterval = () => {
    this.stopInterval();
    this.refreshTimer = setInterval(() => this.fetchReqList(), REFRESH_LIST_TIMER);
  };

  stopInterval = () => {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }
  };

  setShownModal = (modal) => this.setState({ shownModal: modal });

  hideModal = () => this.setState({ shownModal: '' });

  clearNotification = () =>
    this.setState({
      notification: {
        message: '',
        type: '',
      },
    });

  showNotification = ({ message, type }) => {
    this.setState({
      notification: {
        message: typeof message === 'string' ? message : 'Some unexpected error occurred',
        type,
      },
    });
    setTimeout(this.clearNotification, NOTIFICATION_TIMER);
  };

  reqToFindOrDelete = async (payload) => {
    try {
      this.stopInterval();
      await gdprApi.findOrDeletePD(payload);
      if (payload.requestType !== GDPR_API_REQUEST_TYPE.ConfirmDelete) {
        this.showNotification({
          message: 'New request received!',
          type: 'success',
        });
      }
      this.fetchReqList();
    } catch (e) {
      this.showNotification({ message: e, type: 'error' });
    }
  };

  removeReq = async (id) => {
    try {
      await gdprApi.removeRequest(id);
      this.fetchReqList();
    } catch (e) {
      this.showNotification({ message: e, type: 'error' });
    }
  };

  fetchReqList = async () => {
    try {
      this.setState({ isLoading: true });
      const { count, requests } = await gdprApi.fetchRequests({
        sortBy: this.state.sortBy.name,
        order: this.state.sortBy.isAsc ? 'asc' : 'desc',
      });
      const gdprProcessList = requests.map((r) => ({
        ...r,
        extensions: JSON.parse(r.extensions),
      }));
      this.setState({ statusesCount: count, gdprProcessList });
      this.startNewInterval();
    } catch (e) {
      this.showNotification({ message: e, type: 'error' });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <GDPRContext.Provider
        value={{
          ...this.state,
          reqToFindOrDelete: this.reqToFindOrDelete,
          removeReq: this.removeReq,
          fetchReqList: this.fetchReqList,
          confirmDeleting: this.confirmDeleting,
          setShownModal: this.setShownModal,
          hideModal: this.hideModal,
          handleSorting: this.handleSorting,
          clearNotification: this.clearNotification,
          showNotification: this.showNotification,
          handleDownload: this.handleDownload,
        }}
        {...this.props}
      />
    );
  }
}

export const useGDPRContext = () => useContext(GDPRContext);
