import React, { Component } from 'react';
import * as api from 'utils/api';
import { adminBaseUrl } from 'resources';

import './authorizations_list.scss';

class AuthorizationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizations: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchAuthorizations();
  }

  fetchAuthorizations = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await api.get(`${adminBaseUrl}/users/${this.props.userId}/authorizations`);
      const data = await response.json();
      this.setState({ authorizations: response.status === 200 ? data : [], isLoading: false });
    } catch {
      this.setState({ isLoading: false, authorizations: [] });
    }
  };

  addAuth = async (auth) => {
    const response = await api.put(`${adminBaseUrl}/users/${this.props.userId}/add_authorization`, { authorization: auth });
    if (response.status === 201) {
      this.fetchAuthorizations();
      return;
    }
    // eslint-disable-next-line no-alert
    window.alert('Error adding new authorization');
  };

  showAddAuth = async () => {
    // eslint-disable-next-line no-alert
    const newAuth = window.prompt('Enter new authorization id');
    if (newAuth) {
      await this.addAuth(newAuth);
    }
  };

  render() {
    return (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <div className="authorizations-header">
                <p className="card-header-title">Authorizations</p>
                <button className="button is-small add-auth-button" type="button" onClick={this.showAddAuth}>
                  Add Authorization
                </button>
              </div>
            </header>
            {this.state.isLoading && <div>Loading authorizations...</div>}
            {!this.state.isLoading && (
              <div className="card-table">
                <div className="content">
                  <table className="table is-fullwidth is-striped is-bordered authorizations-list">
                    <thead>
                      <tr>
                        <th>
                          Provider
                        </th>
                        <th>
                          ID
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.authorizations.map((authorization) => (
                        <tr key={`${authorization.provider}|${authorization.uid}`}>
                          <td>{authorization.provider}</td>
                          <td>{authorization.uid}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

AuthorizationsList.defaultProps = {
  userId: '',
};

export default AuthorizationsList;
