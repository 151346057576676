import React from 'react';
import ReactDOM from 'react-dom';
import SVG from 'react-inlinesvg';

import './icon.scss';

const iconsList = {
  reload: require('../../../styles/icons/icon-refresh.svg'),
  delete: require('../../../styles/icons/icon-delete.svg'),
  download: require('../../../styles/icons/icon-download.svg'),
  hide: require('../../../styles/icons/icon-hide.svg'),
  success: require('../../../styles/icons/icon-success.svg'),
  close: require('../../../styles/icons/icon-error.svg'),
  warning: require('../../../styles/icons/icon-warning.svg'),
  arrowDown: require('../../../styles/icons/icon-sort-down.svg'),
  dots: require('../../../styles/icons/icon-dots.svg'),
  stop: require('../../../styles/icons/icon-stop.svg'),
  cancel: require('../../../styles/icons/icon-cancel.svg'),
  pen: require('../../../styles/icons/icon-pen.svg'),
  disk: require('../../../styles/icons/icon-disk.svg'),
};

export const Icon = ({ name, className = '', title, ...props }) => (
  <i className={`icon ${className}`} title={title} {...props}>
    <SVG src={iconsList[name] || iconsList.NA} cacheRequests />
  </i>
);

function preloadRenderIcons() {
  const iconEls = Object.keys(iconsList).reduce(
    (arr, item) => [...arr, <SVG key={iconsList[item]} src={iconsList[item] || iconsList.NA} />],
    []
  );

  const div = document.createElement('div');
  document.body.appendChild(div);
  ReactDOM.render(
    React.Children.map(iconEls, (n) => n),
    div
  );

  div.remove();
}

preloadRenderIcons();
