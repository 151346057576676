import React from 'react';
import SVG from 'react-inlinesvg';
import './spinner.scss';

const iconSrc = require('../../../styles/icons/icon-spinner.svg');

const Spinner = ({ className = '' }) => (
  <i className={`spinner-container ${className}`}>
    <SVG src={iconSrc} cacheRequests />
  </i>
);

export default Spinner;
