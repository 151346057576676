import React from 'react';
import PropTypes from 'prop-types';

export default class Day extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(event) {
    if (event) {
      event.preventDefault();
    }
    const { day, disabled } = this.props;
    if (disabled) {
      return;
    }
    this.props.selectDay(day);
  }

  render() {
    const {
      day, active, disabled, dayNextMonth, dayPrevMonth,
    } = this.props;

    const classes = ['tdp-calendar-day'];

    if (day.isSame(active)) {
      classes.push('tdp-calendar-day-active');
    }

    if (disabled) {
      classes.push('tdp-calendar-day-disabled');
    }

    if (dayPrevMonth || dayNextMonth) {
      classes.push('tdp-calendar-day-other-month');
    }

    return (
      <td className={classes.join(' ')}>
        <button onClick={this.handleOnClick} aria-label={day.format('MMMM D YYYY dddd')}>{day.date()}</button>
      </td>
    );
  }
}

Day.propTypes = {
  disabled: PropTypes.bool,
  dayNextMonth: PropTypes.bool,
  dayPrevMonth: PropTypes.bool,
  selectDay: PropTypes.func,
  day: PropTypes.instanceOf(Object),
  active: PropTypes.instanceOf(Object),
};
