import React, { Component } from 'react';
import * as api from 'utils/api';
import { atriumBaseUrl } from 'resources';
import Loading from 'shared/loading';
import Notification from './notification';

class AppStoreImporter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      success: false,
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissNotifications = this.dismissNotifications.bind(this);
  }

  async handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }
    const storeId = this.appStoreId.value;
    if (!storeId) {
      this.setState({
        errors: ['App Store Id is required', ...this.state.errors],
      });
      return;
    }

    this.setState({
      loading: true,
    });

    const store = storeId.match(/\d{9}/) ? 'itunes' : 'android';
    let response;
    try {
      response = await api.post(`${atriumBaseUrl}/ingest/store/${store}/app/${storeId}`, null, {});
    } catch (error) {
      console.error(error);
      this.setState({
        ...this.state,
        errors: [error.toString()],
        loading: false,
      });
      return;
    }
    const { status } = response;

    let data = '';
    try {
      data = await response.text();
    } catch (error) {
      console.error(error);
      data = 'Error parsing response.';
    }

    switch (status) {
      case 201: {
        this.setState({
          ...this.state,
          success: true,
          loading: false,
        });
        break;
      }
      case 404: {
        this.setState({
          ...this.state,
          errors: [data],
          loading: false,
        });
        break;
      }
      default: {
        this.setState({
          ...this.state,
          errors: [data],
          loading: false,
        });
        break;
      }
    }
  }

  dismissNotifications(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      errors: [],
      success: false,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  Request app store data for an app.
                </p>
              </header>
              <div className="card-content">
                <div className="content">
                  {this.state.errors.length ? <Notification type="danger" content={this.state.errors} dismiss={this.dismissNotifications} /> : null}
                  {this.state.success ? <Notification type="info" content={['Success']} dismiss={this.dismissNotifications} /> : null}
                  <form className="app-store-importer" onSubmit={this.handleSubmit}>
                    <div className="field">
                      <label className="label" htmlFor="store-id">
                        App Store ID
                        <input
                          name="store-id"
                          type="text"
                          className="input is-large"
                          placeholder="App Store ID"
                          ref={(input) => this.appStoreId = input}
                        />
                      </label>
                    </div>
                    <div className="control">
                      <button type="submit" className="button is-large is-primary" disabled={this.state.loading}>Request Data</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {this.state.loading ? <Loading /> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default AppStoreImporter;
