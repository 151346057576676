import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EntitlementEditForm from 'components/organizations/entitlement_edit_form';

class EntitlementNew extends Component {
  componentDidMount() {
    document.title = 'Creating Entitlement';
  }

  render() {
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={`/organizations/${this.props.match.params.id}`}>Org: {this.props.match.params.id}</Link>
                </li>
                <li className="is-active">
                  <Link to={`/organizations/${this.props.match.params.id}/new-entitlement`} aria-current="page">Creating Entitlement</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="column">
            <nav className="breadcrumb has-no-separator is-right">
              <ul>
                <li>
                  <Link to={`/organizations/${this.props.match.params.id}/new-entitlement`}>
                    <button className="button is-small" type="button">New Entitlement</button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <EntitlementEditForm
          entitlements={[]}
          entitlementId={null}
          mode="new"
          orgId={this.props.match.params.id}
        />
      </div>
    );
  }
}

export default EntitlementNew;
