import React from 'react';
import { Icon } from '../../shared/icon/icon';
import { bemPrefix } from '../../../utils/bem';

import './gdpr-card.scss';

const bem = bemPrefix('gdpr-card');

export const GdprCard = ({ children, title, icon = null }) => (
  <div className={bem()}>
    <header className={bem('header')}>
      <p className={bem('header-title')}>{title}</p>
      {icon && (
        <div className={`${bem('header-icon')} ${icon.className}`} onClick={icon.handler}>
          <Icon name={icon.name} title={icon.title} />
        </div>
      )}
    </header>
    <div className={bem('body')}>{children}</div>
  </div>
);
