import React, { Component } from 'react';
import { adminBaseUrl } from 'resources/index';
import * as api from 'utils/api';
import Loading from 'shared/loading';
import Notification from 'shared/notification';

class OrganizationEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collaborators: [],
      errors: [],
      hasFetched: false,
    };

    this.fetch = this.fetch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissErrors = this.dismissErrors.bind(this);
  }

  componentDidMount() {
    document.title = `Editing ${this.props.organization.name}`;
    this.fetch(this.props.organization.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.organization.id && !this.state.hasFetched) {
      this.fetch(nextProps.organization.id);
    }
  }

  async handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.organization_name.value) {
      this.setState({
        errors: ['Organization name is required.', ...this.state.errors],
      });
    }

    if (!this.billing_contact_id.value) {
      this.setState({
        errors: ['Organization billing contact is required.', ...this.state.errors],
      });
    }

    const body = {
      name: this.organization_name.value,
      billing_contact_id: this.billing_contact_id.value,
      salesforce_id: this.salesforce_id.value,
      obfuscation_regexp: this.obfuscation_regexp.value,
    };
    if (this.cs_manager_id.value) {
      body.cs_manager_id = this.cs_manager_id.value;
    }

    const response = await api.put(`${adminBaseUrl}/organizations/${this.props.organization.id}`, body);
    const data = await response.json();
    if (data && data.error) {
      this.setState({
        errors: [`Failed to update Organization: ${data.error}`, ...this.state.errors],
      });
    } else {
      window.location.assign(`/organizations/${this.props.organization.id}`);
    }
  }

  async fetch(id) {
    try {
      const response = await api.get(`${adminBaseUrl}/organizations/${id}/collaborators`);
      const data = await response.json();
      if (data && Array.isArray(data)) {
        this.setState({
          collaborators: data,
          hasFetched: true,
        });
      } else if (data && data.error) {
        this.setState({
          errors: [`Failed to fetch Organization Collaborators: ${response.error}`, ...this.state.errors],
          hasFetched: true,
        });
      }
    } catch (error) {
      this.setState({
        errors: [`Failed to fetch Organization Collaborators: ${error}`, ...this.state.errors],
        hasFetched: true,
      });
    }
  }

  dismissErrors(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      errors: [],
    });
  }

  render() {
    const { organization } = this.props;
    return this.state.hasFetched ? (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Edit Organization</p>
            </header>
            <div className="card-content">
              <div className="content">
                {this.state.errors.length ? <Notification type="danger" content={this.state.errors} dismiss={this.dismissErrors} /> : null}
                <form acceptCharset="UTF-8" className="organization" id="organization" onSubmit={this.handleSubmit}>
                  <div className="field">
                    <label className="label" htmlFor="name">
                      Name
                      <div className="control">
                        <input className="input" type="text" placeholder="Organization Name" name="name" defaultValue={organization.name} required ref={(input) => this.organization_name = input} />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="billing_contact_id">
                      Billing Contact
                      <div className="control">
                        <div className="select">
                          <select name="billing_contact_id" id="billing_contact_id" defaultValue={organization.billing_contact.id} required ref={(input) => this.billing_contact_id = input}>
                            <option disabled>Select User...</option>
                            {this.state.collaborators.map(member => (
                              <option key={member.id} value={member.id}>{member.name || member.email}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="cs_manager_id">
                      CS Manager
                      <div className="control">
                        <div className="select">
                          <select name="cs_manager_id" id="cs_manager_id" defaultValue={organization.cs_manager.id} ref={(input) => this.cs_manager_id = input}>
                            <option disabled>Select User...</option>
                            <option value="58a22c60f40be3413e000012">Casey Anderson</option>
                            <option value="5522f5edddf2c7dec80002a9">Patrick Cotter</option>
                            <option value="58336627dd7d251da800000b">Veronica Rosenberg</option>
                            <option value="5cb6366d4dd69655ad000045">Chris Diakiwski</option>
                            <option value="5e8e53730f4166143f00000f">Kira Castle</option>
                            <option value="61f870995a8827264e000150">Heidi Funston</option>
                            <option value="62d5ce8066802509d1000048">Kenia Rivas-Duarte</option>
                            <option value="642f1fb0b4601010f452ee5e">Nancy Sully</option>
                            <option value="5da7aea7b54ae34713000083">Support</option>
                          </select>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="salesforce_id">
                      Salesforce ID
                      <div className="control">
                        <input className="input" type="text" placeholder="Salesforce ID" name="salesforce_id" defaultValue={organization.salesforce_id} ref={(input) => this.salesforce_id = input} />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="obfuscation_regexp">
                      Obfuscation Regex
                      <div className="control">
                        <input className="input" type="text" placeholder="Obfuscation Regex" name="obfuscation_regexp" defaultValue={organization.obfuscation_regexp} ref={(input) => this.obfuscation_regexp = input} />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button className="button is-link">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (<Loading text="Loading Organization Edit Form..." />);
  }
}

OrganizationEditForm.defaultProps = {
  organization: {
    name: '',
    plan: {},
    cs_manager: {},
    billing_contact: {},
    limit_interactions: false,
  },
};

export default OrganizationEditForm;
