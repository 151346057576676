import React, { Component } from 'react';
import * as api from 'utils/api';
import Loading from 'shared/loading';
import UserDetails from 'components/users/user_details';
import { adminBaseUrl } from 'resources';

class UserShow extends Component {
  constructor(params) {
    super(params);
    this.state = {
      data: {
        admin: false,
        hasFetched: false,
        invited_by: {},
      },
      loading: true,
    };

    this.fetch = this.fetch.bind(this);
    this.favorite = this.favorite.bind(this);
  }

  componentDidMount() {
    document.title = 'User Show';
    this.fetch(this.props.match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.match.params.id && !this.state.hasFetched) {
      this.fetch(nextProps.match.params.id);
    }
  }

  async fetch(id) {
    const response = await api.get(`${adminBaseUrl}/users/${id}`);
    try {
      const data = await response.json();
      this.setState({
        data,
        loading: false,
        hasFetched: true,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        hasFetched: false,
      });
    }
  }

  favorite(id) {
    return (_event) => {
      api.post(`${adminBaseUrl}/favorites?id=${id}&type=users`);
    };
  }

  render() {
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/users">Users List</a>
                </li>
                <li className="is-active">
                  <a href={`/users/${this.props.match.params.id}`} aria-current="page">{this.props.match.params.id}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="column">
            <nav className="breadcrumb has-no-separator is-right">
              <ul>
                <li><button className="button is-small" type="button" onClick={this.favorite(this.props.match.params.id)}>Favorite</button></li>
              </ul>
            </nav>
          </div>
        </div>
        {this.state.loading ? <Loading text="Loading..." /> : <UserDetails key={`${this.props.match.params.id}-UserDetails`} user={this.state.data} />}
      </div>
    );
  }
}

export default UserShow;
