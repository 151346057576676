import React from 'react';
import PropTypes from 'prop-types';
import Combobox from './combobox';
import Token from './token';

class TokenInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedToken: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleRemoveLast = this.handleRemoveLast.bind(this);
  }

  handleClick(_event) {
    this.comboLi.querySelector('input').focus();
  }

  handleKeyDown(event) {
    if (event && event.keyCode === 13) {
      this.comboLi.querySelector('input').focus();
    }
  }

  handleFocus() {
    this.props.onFocus();
  }

  handleBlur() {
    this.props.onBlur();
  }

  handleInput(inputValue) {
    this.props.onInput(inputValue);
  }

  handleSelect(event, option) {
    const input = this.comboLi.querySelector('input');
    this.props.onSelect(event, option);
    this.setState({
      selectedToken: null,
    });
    this.props.onInput(input.value);
  }

  handleRemove(value, option) {
    const input = this.comboLi.querySelector('input');
    this.props.onRemove(value, option);
    input.focus();
  }

  handleRemoveLast() {
    const last = this.props.selected[this.props.selected.length - 1];
    if (last) {
      this.handleRemove(last.value, last);
    }
  }

  render() {
    return (
      <ul
        className={`tokens ${this.props.isDisabled ? 'tokens-disabled' : ''}`}
        onClick={this.handleClick}
        onKeyDown={this.handleKeyDown}
        role="presentation"
      >
        {this.props.selected.map((token) => (
          <Token
            key={token.value}
            name={token.name}
            onFocus={this.handleFocus}
            onRemove={this.handleRemove}
            option={token}
            value={token.value}
          />
        ))}
        <li className="inline-flex" ref={(ref) => this.comboLi = ref}>
          <Combobox
            aria-disabled={this.props.isDisabled}
            aria-label={this.props['combobox-aria-label']}
            id={this.props.id}
            isDisabled={this.props.isDisabled}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onInput={this.handleInput}
            onRemoveLast={this.handleRemoveLast}
            onSelect={this.handleSelect}
            placeholder={this.props.placeholder}
            showListOnFocus={this.props.showListOnFocus}
            value={this.state.selectedToken}
            menuContent={this.props.menuContent}
          />
        </li>
      </ul>
    );
  }
}

TokenInput.propTypes = {
  isDisabled: PropTypes.bool,
  menuContent: PropTypes.any,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selected: PropTypes.array.isRequired,
  showListOnFocus: PropTypes.bool,
};

TokenInput.defaultProps = {
  isDisabled: false,
  menuContent: [],
  onBlur: () => {},
  onFocus: () => {},
  // onInput: () => {},
  // onRemove: () => {},
  // onSelect: () => {},
  placeholder: '',
  // selected: [],
  showListOnFocus: true,
};

export default TokenInput;
