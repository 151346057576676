import React, { createContext, useContext } from 'react';
import rolloutApi from './rollout-service';

const INITIAL_STATE = {
  featureFlags: [],
  isLoading: false,
};

const noop = () => null;

export const RolloutContext = createContext({
  ...INITIAL_STATE,
  getFlags: noop,
  createNewFlag: noop,
  getFeatureSettings: noop,
  updateFeatureSettings: noop,
});

export class RolloutContextProvider extends React.PureComponent {
  state = { ...INITIAL_STATE };

  componentDidMount = () => this.getFlags();

  getFlags = async () => {
    try {
      this.setState({ isLoading: true });
      const { features } = await rolloutApi.fetchFeatureFlags();
      this.setState({ featureFlags: features, isLoading: false });
    } catch (e) {
      this.setState({ featureFlags: [], isLoading: false });
    }
  };

  createNewFlag = async (payload) => {
    try {
      this.setState({ isLoading: true });
      await rolloutApi.createFeatureFlag(payload);
      this.setState({ featureFlags: [...this.state.featureFlags, payload.feature], isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  getFeatureSettings = async (feature) => {
    try {
      const featureSettings = await rolloutApi.fetchFeatureSettings(feature);
      return featureSettings;
    } catch (e) {
      throw new Error(e);
    }
  };

  updateFeatureSettings = (settings) => rolloutApi.updateFeatureSettings(settings);

  render() {
    return (
      <RolloutContext.Provider
        value={{
          ...this.state,
          getFlags: this.getFlags,
          createNewFlag: this.createNewFlag,
          getFeatureSettings: this.getFeatureSettings,
          updateFeatureSettings: this.updateFeatureSettings,
        }}
        {...this.props}
      />
    );
  }
}

export const useRolloutContext = () => useContext(RolloutContext);
