export const GDPR_API_GROUP_TYPE = {
  email: 'email',
  cid: 'cid',
  convid: 'conversation_id',
};

export const GDPR_API_REQUEST_TYPE = {
  Access: 'access',
  ReqToDelete: 'request_to_forget',
  ConfirmDelete: 'erasure',
};

export const GDPR_API_REQUEST_STATUS = {
  InProgress: 'in_progress',
  Completed: 'completed',
  Failed: 'failed',
};

export const GDPR_API_PROCESS_TYPE = {
  Access: 'access',
  Delete: 'delete',
};

export const GDPR_API_ACTIVITY_TYPE = {
  Search: 'search',
  Delete: 'delete',
};

export const GDPR_SORT_BY_OPTION = {
  Date: 'date',
  Group: 'identity_type',
  Activity: 'activity',
  Status: 'status',
  Creator: 'created_by',
  Null: '',
};

export const GROUP_LABEL = {
  email: 'Email',
  cid: 'CID',
  conversation_id: 'Conversation',
};

export const REQUEST_LABEL = {
  find: 'Find',
  delete: 'Delete',
};

export const ACTIVITY_LABEL = {
  search: 'Search',
  delete: 'Delete',
};

export const STATUS_LABEL = {
  [GDPR_API_REQUEST_STATUS.Completed]: 'Completed',
  [GDPR_API_REQUEST_STATUS.Failed]: 'Error',
  [GDPR_API_REQUEST_STATUS.InProgress]: 'In Progress',
};

export const STATUS_ICON = {
  completed: 'success',
  failed: 'close',
};

export const MODAL_TYPE = {
  erasure: 'erasure',
  delete: 'delete',
};
