import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { sortBy, prop, reverse } from 'ramda';
import { atriumBaseUrl, adminBaseUrl } from 'resources';
import * as api from 'utils/api';

class AppMembershipsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      hasFetched: false,
      sortKey: 'organization_name',
      sortReverse: false,
    };

    this.fetch = this.fetch.bind(this);
    this.sortByKey = this.sortByKey.bind(this);
    this.removeAppMembership = this.removeAppMembership.bind(this);
  }

  componentDidMount() {
    this.fetch(this.props.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.id && !this.state.hasFetched) {
      this.fetch(nextProps.id);
    }
  }

  async fetch(id) {
    const response = await api.get(`${adminBaseUrl}/users/${id}/app_memberships`);
    const data = await response.json();
    this.setState({
      data,
      hasFetched: true,
    });
  }

  sortByKey(sortKey) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      let sortReverse = false;
      let data = sortBy(prop(sortKey), this.state.data);
      if (sortKey === this.state.sortKey && this.state.sortReverse === false) {
        sortReverse = true;
        data = reverse(data);
      }
      this.setState({ sortKey, data, sortReverse });
    };
  }

  async removeAppMembership(appId, appMemberId, orgId) {
    const response = await api.get(`${adminBaseUrl}/organizations/${orgId}`);
    const data = await response.json();
    const userId = window.location.pathname.replace('/users/', '');
    if (data && data.billing_contact && data.billing_contact.id === userId) {
      window.alert(
        'This member is a billing contact. Please make sure you changed the billing contact for the app and try one more time.'
      );
      return;
    }
    if (window.confirm('Are you sure you want to remove this membership?')) {
      api.del(`${atriumBaseUrl}/apps/${appId}/app_members/${appMemberId}`);
    }
  }

  render() {
    return (
      <table className="table is-fullwidth is-striped is-bordered app-memberships-list">
        <thead>
          <tr>
            <th className="sort-by" onClick={this.sortByKey('organization_name')}>
              Organization
            </th>
            <th className="sort-by" onClick={this.sortByKey('app_title')}>
              App
            </th>
            <th className="sort-by" onClick={this.sortByKey('app_platform')}>
              Platform
            </th>
            <th className="sort-by" onClick={this.sortByKey('role')}>
              Role
            </th>
            <th className="sort-by" onClick={this.sortByKey('app_cached_mau')}>
              MAU
            </th>
            <th className="sort-by" onClick={this.sortByKey('app_live')}>
              Live
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.map((membership) => (
            <tr key={membership.id}>
              <td>
                <Link to={`/organizations/${membership.organization_id}`}>{membership.organization_name}</Link>
              </td>
              <td>
                <Link to={`/apps/${membership.app_id}`}>{membership.app_title}</Link>
              </td>
              <td>{membership.app_platform}</td>
              <td>{membership.role}</td>
              <td>{membership && membership.app_cached_mau && membership.app_cached_mau.toLocaleString()}</td>
              <td>{membership.app_live === true ? 'Live' : ''}</td>
              <td>
                <button
                  className="button is-small"
                  type="button"
                  onClick={() => this.removeAppMembership(membership.app_id, membership.id, membership.organization_id)}
                >
                  Remove from App
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default AppMembershipsList;
