import cookies from 'browser-cookies';
import jwt_decode from 'jwt-decode';
import Session from 'utils/shared_session';

const OAUTH_COOKIE = 'oauth_jwt';

export default class jwt {
  static set(token) {
    Session.set('token', token);
  }

  static getToken() {
    if (jwt.oauthJWT()) {
      jwt.set(jwt.oauthJWT());
      jwt.oauthClearJWT();
    }
    return Session.get('token');
  }

  static decode(token) {
    if (!token) {
      console.warn('No JWT token');
      return null;
    }
    if (typeof token !== 'string') {
      console.warn('Something other than a JWT token was passed in.', token);
      return null;
    }

    const decodedPayload = jwt_decode(token);
    return decodedPayload;
  }

  static get() {
    const token = jwt.getToken();
    return jwt.decode(token);
  }

  static clearLocalStorage() {
    // Iterate over localStorage and remove the keys that meet the conditions
    try {
      const keys = [];
      for (let i = 0, l = localStorage.length; i < l; i++) {
        /* istanbul ignore else */
        if (localStorage.key(i)) {
          const prefix = localStorage.key(i).substring(0, 6);
          if (prefix === 'auth0.' || prefix === 'com.au' || prefix === 'shared') {
            keys.push(localStorage.key(i));
          }
        }
      }
      for (let i = 0, l = keys.length; i < l; i++) {
        /* istanbul ignore else */
        if (keys[i]) {
          localStorage.removeItem(keys[i]);
        }
      }
    } catch (error) {
      /* istanbul ignore next */
      console.error('Error Clearing localStorage', error);
    }
  }

  static clear() {
    Session.remove('token');
    Session.remove('nonce');
  }

  static ttl() {
    const session = jwt.get();
    return session ? (session.exp * 1000) - Date.now() : 0;
  }

  static valid() {
    return jwt.ttl() > 0;
  }

  static setNonce(nonce) {
    Session.set('nonce', nonce);
  }

  static getNonce() {
    return Session.get('nonce');
  }

  static generateNonce(length = 16) {
    const bytes = new Uint8Array(length);
    let nonce;
    try {
      const random = window.msCrypto ? window.msCrypto.getRandomValues(bytes) : window.crypto.getRandomValues(bytes);
      const result = [];
      const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
      random.forEach((c) => {
        result.push(charset[c % charset.length]);
      });
      nonce = result.join('');
    } catch (error) {
      console.error('window.crypto Error:', error);
      nonce = (Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2)).substr(0, length);
    }
    jwt.setNonce(nonce);
    return nonce;
  }

  static oauthJWT() {
    return cookies.get(OAUTH_COOKIE);
  }

  static oauthClearJWT() {
    return jwt.forceRemoveCookie(OAUTH_COOKIE);
  }

  static forceRemoveCookie(name, host = window.location.hostname, cookieInterface = cookies) {
    const subdomains = host.split('.');
    subdomains.forEach((subdomain, i) => {
      if (i >= subdomains.length - 1) return;
      const domain = subdomains.slice(i).join('.');
      cookieInterface.erase(name, { domain });
      cookieInterface.erase(name, { domain: `.${domain}` });
    });
  }
}
