import React from 'react';
import { bemPrefix } from '../../utils/bem';
import KeysList from './keys-list';

const bem = bemPrefix('keys-page');

function KeysPageContainer() {
  return (
    <div className={bem('')}>
      <KeysList />
    </div>
  );
}

export default KeysPageContainer;
