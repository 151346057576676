import React, { Component } from 'react';
import ListView from 'shared/list_view';
import Loading from 'shared/loading';
import * as resources from 'resources';
import * as api from 'utils/api';
import AppSearchResult from 'components/apps/app_search_result';
import OrganizationSearchResult from 'components/organizations/organization_search_result';
import UserSearchResult from 'components/users/user_search_result';

class Search extends Component {
  constructor(params) {
    super(params);
    this.state = {
      value: '',
      apps: [],
      organizations: [],
      users: [],
      loadingApps: false,
      loadingOrganizations: false,
      loadingUsers: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.search = this.search.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      loadingApps: true,
      loadingOrganizations: true,
      loadingUsers: true,
    });

    this.search(resources.apps);
    this.search(resources.orgs);
    this.search(resources.users);
  }

  async search(resource) {
    const response = await api.get(`${resource.url}?query=${this.state.value}`);
    const data = await response.json();
    this.setState({
      [resource.name]: data[resource.name],
      [`loading${resource.title}`]: false,
    });
  }

  render() {
    let appsView = null;
    if (this.state.loadingApps) {
      appsView = <Loading text="Searching Apps..." />;
    } else {
      appsView = <ListView data={this.state.apps} sub={AppSearchResult} resource={resources.apps} />;
    }

    let orgsView = null;
    if (this.state.loadingOrganizations) {
      orgsView = <Loading text="Searching Organizations..." />;
    } else {
      orgsView = <ListView data={this.state.organizations} sub={OrganizationSearchResult} resource={resources.orgs} />;
    }

    let usersView = null;
    if (this.state.loadingUsers) {
      usersView = <Loading text="Searching Users..." />;
    } else {
      usersView = <ListView data={this.state.users} sub={UserSearchResult} resource={resources.users} />;
    }

    const disableSearch = this.state.loadingApps || this.state.loadingOrganizations || this.state.loadingUsers;

    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li className="is-active">
                  <a href="/">Home</a>
                </li>
              </ul>
            </nav>
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">Search</p>
              </header>
              <div className="card-content">
                <div className="content">
                  <form onSubmit={this.handleSubmit} className="field has-addons">
                    <div className="control is-expanded">
                      <input className="input is left is-large" type="text" name="query" placeholder="Search Apps, Orgs and Users..." value={this.state.value} onChange={this.handleChange} />
                    </div>
                    <div className="control">
                      <button className="button is-right is-large is-primary" type="submit" disabled={disableSearch}>Search</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {appsView}
            {orgsView}
            {usersView}
          </div>
        </div>
      </div>
    );
  }
}

export default Search;
