export const baseUrl = process.env.WEB_URL;
export const atriumBaseUrl = process.env.ATRIUM_URL;
export const adminBaseUrl = `${atriumBaseUrl}/apptentive_admin`;

export const apps = {
  name: 'apps',
  title: 'Apps',
  url: `${adminBaseUrl}/apps`,
  path: '/apps',
};
export const orgs = {
  name: 'organizations',
  title: 'Organizations',
  url: `${adminBaseUrl}/organizations`,
  path: '/organizations',
};
export const users = {
  name: 'users',
  title: 'Users',
  url: `${adminBaseUrl}/users`,
  path: '/users',
};
export const currentUser = { url: `${atriumBaseUrl}/users/current` };
