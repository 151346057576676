import React, { useState, useCallback } from 'react';
import { isEmpty } from 'ramda';
import classNames from 'classnames';
import { useGDPRContext } from '../gdpr-context';
import { GDPR_API_REQUEST_TYPE, GDPR_SORT_BY_OPTION, MODAL_TYPE } from '../gdpr-types';
import { bemPrefix } from '../../../utils/bem';
import { Icon } from '../../shared/icon/icon';
import Spinner from '../../shared/spinner/spinner';
import { GdprModalContainer } from '../gdpr-modal/gdpr-modal';
import { GdprStatusItem } from './gdpr-request-item';

import './gdpr-requests.scss';

const bem = bemPrefix('gdpr-status-list');

const headers = [
  {
    label: 'DATE',
    sorting: GDPR_SORT_BY_OPTION.Date,
    className: bem('table-head-row-date'),
  },
  {
    label: 'PROCESS',
    sorting: GDPR_SORT_BY_OPTION.Null,
    className: bem('table-head-row-process'),
  },
  {
    label: 'ORGANIZATION',
    sorting: GDPR_SORT_BY_OPTION.Null,
    className: bem('table-head-row-org'),
  },
  {
    label: 'DESCRIPTION',
    sorting: GDPR_SORT_BY_OPTION.Null,
    className: bem('table-head-row-descr'),
  },
  {
    label: 'GROUP',
    sorting: GDPR_SORT_BY_OPTION.Group,
    className: bem('table-head-row-group'),
  },
  {
    label: 'ID',
    sorting: GDPR_SORT_BY_OPTION.Null,
    className: bem('table-head-row-id'),
  },
  {
    label: 'ACTIVITY',
    sorting: GDPR_SORT_BY_OPTION.Activity,
    className: bem('table-head-row-activity'),
  },
  {
    label: 'STATUS',
    sorting: GDPR_SORT_BY_OPTION.Status,
    className: bem('table-head-row-status'),
  },
  {
    label: 'CREATOR',
    sorting: GDPR_SORT_BY_OPTION.Creator,
    className: bem('table-head-row-creator'),
  },
  {
    label: 'ACTIONS',
    sorting: GDPR_SORT_BY_OPTION.Null,
    className: bem('table-head-row-actions'),
  },
];

export const GdprStatusesList = () => {
  const {
    gdprProcessList,
    reqToFindOrDelete,
    removeReq,
    isLoading,
    shownModal,
    setShownModal,
    hideModal,
    handleSorting,
  } = useGDPRContext();

  const [requestToDelete, setRequestToDelete] = useState(null);
  const [requestToErasure, setRequestToErasure] = useState(null);

  const openModal = useCallback(
    (request, operation) => {
      if (operation === MODAL_TYPE.delete) {
        setRequestToDelete(request);
      } else {
        setRequestToErasure(request);
      }
      setShownModal(operation);
    },
    [shownModal]
  );

  const closeModal = useCallback(() => {
    hideModal();
    setRequestToDelete(null);
    setRequestToErasure(null);
  }, [shownModal]);

  const handleDelete = useCallback(() => {
    hideModal();
    if (shownModal === MODAL_TYPE.delete) {
      removeReq(requestToDelete.id);
      return;
    }

    reqToFindOrDelete({
      ...requestToErasure,
      requestType: GDPR_API_REQUEST_TYPE.ConfirmDelete,
    });
  }, [shownModal]);

  return (
    <>
      {shownModal && (
        <GdprModalContainer
          request={requestToDelete}
          submitLabel="Yes"
          submitHandler={handleDelete}
          cancelHandler={closeModal}
          shownModal={shownModal}
        />
      )}
      <div className={classNames(bem(), shownModal && 'with-blur')}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {!gdprProcessList || isEmpty(gdprProcessList) ? (
              <p className={bem('message')}>There are no actual requests</p>
            ) : (
              <>
                <table className={bem('table')}>
                  <thead className={bem('table-head')}>
                    <tr className={bem('table-head-row')}>
                      {headers.map(({ label, sorting, className }) => (
                        <td key={label} className={className}>
                          <div
                            className={classNames(
                              bem('table-head-column'),
                              label === 'Actions' && bem('actions-column')
                            )}
                          >
                            <span>{label}</span>
                            {sorting && (
                              <div
                                className={bem('table-head-column-icons-block')}
                                onClick={() => handleSorting(sorting)}
                              >
                                <Icon name="arrowDown" className={bem('arrow-up')} />
                                <Icon name="arrowDown" className={bem('arrow-down')} />
                              </div>
                            )}
                          </div>
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody className={bem('table-body')}>
                    {gdprProcessList
                      .filter((item) => !item.is_deleted)
                      .map((p) => (
                        <GdprStatusItem key={p.id} request={p} openModal={openModal} />
                      ))}
                  </tbody>
                </table>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
