import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as api from 'utils/api';
import Notification from 'shared/notification';
import { adminBaseUrl } from 'resources';

const TEN_MINUTE_CACHE_INTERVAL = 600;

class AppSettings extends Component {
  constructor(params) {
    super(params);
    this.state = {
      errors: [],
      cacheBoxChecked: undefined,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissNotifications = this.dismissNotifications.bind(this);
    this.handleChangeCacheCheckbox = this.handleChangeCacheCheckbox.bind(this);
  }

  handleChangeCacheCheckbox(event) {
    const { checked } = event.target;
    this.setState({ cacheBoxChecked: checked });
  }

  async handleSubmit(event) {
    event.preventDefault();

    const response = await api.put(
      `${adminBaseUrl}/apps/${this.props.app.id}`,
      {
        preferences: {
          interaction_cache: this.state.cacheBoxChecked
            ? TEN_MINUTE_CACHE_INTERVAL
            : null,
        },
      }
    );

    if (response && response.ok) {
      this.setState({
        saving: false,
        success: true,
        cacheBoxChecked: undefined,
      });
    } else if (response) {
      const json = await response.json();

      this.setState({
        saving: false,
        success: false,
        errors: [].concat(json.error || []),
      });
    }
  }

  dismissNotifications(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      errors: [],
      success: false,
    });
  }

  renderSettingsForm() {
    return (
      <form
        acceptCharset="UTF-8"
        className="app-settings"
        onSubmit={this.handleSubmit}
      >
        <div className="field checkbox">
          <label className="checkbox" htmlFor="manifest-cache">
            <input
              name="manifest-cache"
              type="checkbox"
              disabled={this.state.saving || this.props.app.mau_active !== true}
              defaultChecked={
                this.props.app.preferences &&
                this.props.app.preferences.interaction_cache ===
                  TEN_MINUTE_CACHE_INTERVAL
              }
              onChange={this.handleChangeCacheCheckbox}
            />
            Use a short 10 minute manifest cache rather than the default 8
            hours. <strong>WARNING:</strong> this should only be enabled for
            special app events. Once the event is over, please return and
            un-check this box.
          </label>
        </div>

        <div className="field">
          <div className="control">
            <button
              type="submit"
              className="button is-primary"
              disabled={this.state.saving || typeof this.state.cacheBoxChecked === 'undefined'}
            >
              Save Settings
            </button>
          </div>
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">App Settings</p>
              </header>
              <div className="card-content">
                <div className="content">
                  {this.state.errors.length > 0 && (
                    <Notification
                      type="danger"
                      content={this.state.errors}
                      dismiss={this.dismissNotifications}
                    />
                  )}
                  {this.state.success && (
                    <Notification
                      type="info"
                      content={['App settings updated']}
                      dismiss={this.dismissNotifications}
                    />
                  )}
                  {this.props.app && this.renderSettingsForm()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AppSettings.defaultProps = {
  app: {
    id: '',
    mau_active: false,
    preferences: {
      interaction_cache: 0,
    },
  },
};

AppSettings.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string,
    mau_active: PropTypes.bool,
    preferences: PropTypes.shape({
      interaction_cache: PropTypes.number,
    }),
  }),
};

export default AppSettings;
