import React from 'react';
import classNames from 'classnames';
import { Icon } from '../../shared/icon/icon';
import { bemPrefix } from '../../../utils/bem';
import { useGDPRContext } from '../gdpr-context';

import './gdpr-notification.scss';

const bem = bemPrefix('gdpr-notification');

export const GdprNotification = () => {
  const { notification, clearNotification } = useGDPRContext();
  return (
    <div className={classNames(bem(), notification.type)}>
      {notification.message && notification.type && (
        <>
          <span>{notification.message}</span>
          <div onClick={clearNotification} className={bem('icon-close')}>
            <Icon name="close" />
          </div>
        </>
      )}
    </div>
  );
};
