import React from 'react';
import { Link } from 'react-router-dom';
import storeFormat from 'utils/store_format';

const AppDetails = ({ app = {} }) => (
  <div className="columns">
    <div className="column">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">App Details</p>
        </header>
        <div className="card-table">
          <div className="content">
            <table className="table is-fullwidth is-striped is-bordered">
              <tbody>
                <tr>
                  <td>Organization:</td>
                  <td><Link to={`/organizations/${app?.organization?.id}`}>{app?.organization?.name}</Link></td>
                </tr>
                <tr>
                  <td>Title:</td>
                  <td>{app.title}</td>
                </tr>
                <tr>
                  <td>Platform:</td>
                  <td>{app.platform}</td>
                </tr>
                <tr>
                  <td>Went Live On:</td>
                  <td>{app.went_live_on}</td>
                </tr>
                <tr>
                  <td>App Store ID:</td>
                  <td>{storeFormat(app.store_app_id, app.platform)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div className="column">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">Usage</p>
        </header>
        <div className="card-table">
          <div className="content">
            <table className="table is-fullwidth is-striped is-bordered">
              <tbody>
                <tr>
                  <td>MAU:</td>
                  <td>{app?.mau_count?.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Collaborator Count:</td>
                  <td>{app?.collaborator_count?.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Billable Interactions:</td>
                  <td>{app?.billable_interaction_count?.toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Using Customer Auth?:</td>
                  <td>{app.used_customer_authentication ? 'Yes' : 'No'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div className="column">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">Auth Info</p>
        </header>
        <div className="card-table">
          <div className="content">
            <table className="table is-fullwidth is-striped is-bordered">
              <tbody>
                <tr>
                  <td>Legacy API Key:</td>
                  <td>{app.api_token}</td>
                </tr>
                <tr>
                  <td>Beta API Key:</td>
                  <td>{app.beta_api_token}</td>
                </tr>
                <tr>
                  <td>App Key:</td>
                  <td>{app.authentication_key}</td>
                </tr>
                <tr>
                  <td>App Signature:</td>
                  <td>{app.authentication_signature}</td>
                </tr>
                <tr>
                  <td>JWT Secret:</td>
                  <td>{app.jwt_secret}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AppDetails;
