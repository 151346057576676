import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { sortBy, prop, reverse } from 'ramda';
import { adminBaseUrl } from 'resources/index';
import * as api from 'utils/api';
import dateFormat from 'utils/date_format';
import Loading from 'shared/loading';
import Notification from 'shared/notification';

class EntitlementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      errors: [],
      hasFetched: false,
      sortKey: 'id',
      sortReverse: false,
    };

    this.fetch = this.fetch.bind(this);
    this.dismissErrors = this.dismissErrors.bind(this);
    this.sortByKey = this.sortByKey.bind(this);
  }

  componentDidMount() {
    this.fetch(this.props.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.id && !this.state.hasFetched) {
      this.fetch(nextProps.id);
    }
  }

  async fetch(id) {
    try {
      const response = await api.get(`${adminBaseUrl}/organizations/${id}/entitlements`);
      const data = await response.json();
      if (data && Array.isArray(data)) {
        this.setState({
          data,
          hasFetched: true,
        });
      } else if (data && data.error) {
        this.setState({
          errors: [`Failed to fetch Organization Entitlements: ${data.error}`, ...this.state.errors],
          hasFetched: true,
        });
      }
    } catch (error) {
      this.setState({
        errors: [`Failed to fetch Organization Entitlements: ${error}`, ...this.state.errors],
        hasFetched: true,
      });
    }
  }

  dismissErrors(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      errors: [],
    });
  }

  sortByKey(sortKey) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      let sortReverse = false;
      let data = sortBy(prop(sortKey), this.state.data);
      if (sortKey === this.state.sortKey && this.state.sortReverse === false) {
        sortReverse = true;
        data = reverse(data);
      }
      this.setState({ sortKey, data, sortReverse });
    };
  }

  render() {
    return this.state.hasFetched ? (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Entitlements</p>
            </header>
            <div className="card-table">
              <div className="content">
                {this.state.errors.length ? (
                  <Notification type="danger" content={this.state.errors} dismiss={this.dismissErrors} />
                ) : null}
                <table className="table is-fullwidth is-striped is-bordered entitlement-list">
                  <thead>
                    <tr>
                      <th className="sort-by" onClick={this.sortByKey('id')}>
                        ID
                      </th>
                      <th className="sort-by" onClick={this.sortByKey('tier')}>
                        Tier
                      </th>
                      <th className="sort-by" onClick={this.sortByKey('term')}>
                        Term
                      </th>
                      <th className="sort-by" onClick={this.sortByKey('mau_limit')}>
                        MAU Limit
                      </th>
                      <th className="sort-by" onClick={this.sortByKey('collaborator_limit')}>
                        Collaborator Limit
                      </th>
                      <th className="sort-by" onClick={this.sortByKey('interaction_limit')}>
                        Interaction Limit
                      </th>
                      <th className="sort-by" onClick={this.sortByKey('app_limit')}>
                        App Limit
                      </th>
                      <th className="sort-by" onClick={this.sortByKey('started_on')}>
                        Start Date
                      </th>
                      <th className="sort-by" onClick={this.sortByKey('ended_on')}>
                        End Date
                      </th>
                      <th className="sort-by" onClick={this.sortByKey('created_at')}>
                        Created
                      </th>
                      <th className="sort-by" onClick={this.sortByKey('active')}>
                        Active
                      </th>
                      <th className="sort-by" onClick={this.sortByKey('future')}>
                        Future
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((entitlement) => (
                        <tr key={entitlement.id}>
                          <td>
                            <Link to={`/organizations/${this.props.id}/entitlement/${entitlement.id}`}>
                              {entitlement.id}
                            </Link>
                          </td>
                          <td>{entitlement.tier}</td>
                          <td>{entitlement.term}</td>
                          <td>{entitlement.mau_limit && entitlement.mau_limit.toLocaleString()}</td>
                          <td>{entitlement.collaborator_limit && entitlement.collaborator_limit.toLocaleString()}</td>
                          <td>{entitlement.interaction_limit && entitlement.interaction_limit.toLocaleString()}</td>
                          <td>{entitlement.app_limit && entitlement.app_limit.toLocaleString()}</td>
                          <td>{dateFormat(entitlement.started_on)}</td>
                          <td>{dateFormat(entitlement.ended_on)}</td>
                          <td>{dateFormat(entitlement.created_at)}</td>
                          <td>{entitlement.active ? 'Active' : ''}</td>
                          <td>{entitlement.future ? 'Future' : ''}</td>
                          <td>
                            <Link to={`/organizations/${this.props.id}/entitlement/${entitlement.id}/edit`}>Edit</Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Loading text="Loading Entitlements..." />
    );
  }
}

export default EntitlementList;
