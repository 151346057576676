import React from 'react';

const ListView = (props, _context) => {
  let header;
  let empty;
  switch (props.resource.name) {
    case 'apps':
      header = (
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Platform</th>
          <th>Live?</th>
          <th>MAU Count</th>
          <th>Org Name</th>
          <th>Created At</th>
        </tr>
      );
      empty = (
        <tr>
          <td className="no-data" colSpan={7}>No App Data</td>
        </tr>
      );
      break;
    case 'organizations':
      header = (
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>MAU Count</th>
          <th>Created At</th>
        </tr>
      );
      empty = (
        <tr>
          <td className="no-data" colSpan={4}>No Org Data</td>
        </tr>
      );
      break;
    case 'users':
      header = (
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Created At</th>
        </tr>
      );
      empty = (
        <tr>
          <td className="no-data" colSpan={4}>No User Data</td>
        </tr>
      );
      break;
    default:
      header = <tr />;
      empty = (
        <tr>
          <td className="no-data">No Data</td>
        </tr>
      );
      break;
  }

  return (
    <div className="card events-card">
      <header className="card-header">
        <p className="card-header-title">{props.resource.title} List View</p>
      </header>
      <div className="card-table">
        <div className="content">
          <table className="table is-fullwidth is-striped is-bordered">
            <thead>{header}</thead>
            <tbody>
              {props.data.length ? props.data.map(item => <props.sub resource={props.resource} item={item} key={item.id} />) : empty}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ListView;

ListView.defaultProps = {
  data: [],
  resource: {},
};
