import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { sortBy, prop, reverse } from 'ramda';
import { adminBaseUrl } from 'resources/index';
import * as api from 'utils/api';
import dateFormat from 'utils/date_format';
import storeFormat from 'utils/store_format';

class AppsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      hasFetched: false,
      sortKey: 'title',
      sortReverse: false,
    };

    this.fetch = this.fetch.bind(this);
    this.favorite = this.favorite.bind(this);
    this.sortByKey = this.sortByKey.bind(this);
  }

  componentDidMount() {
    this.fetch(this.props.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.id && !this.state.hasFetched) {
      this.fetch(nextProps.id);
    }
  }

  async fetch(id) {
    const response = await api.get(`${adminBaseUrl}/organizations/${id}/apps`);
    const data = await response.json();
    if (data && data.apps) {
      this.setState({
        data: data.apps,
        hasFetched: true,
      });
    } else if (data && data.error) {
      console.error(data.error);
    }
  }

  favorite(id) {
    return (_event) => {
      api.post(`${adminBaseUrl}/favorites?id=${id}&type=apps`);
    };
  }

  sortByKey(sortKey) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      let sortReverse = false;
      let data = sortBy(prop(sortKey), this.state.data);
      if (sortKey === this.state.sortKey && this.state.sortReverse === false) {
        sortReverse = true;
        data = reverse(data);
      }
      this.setState({ sortKey, data, sortReverse });
    };
  }

  render() {
    return (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Apps</p>
            </header>
            <div className="card-table">
              <div className="content">
                <table className="table is-fullwidth is-striped is-bordered app-list">
                  <thead>
                    <tr>
                      <th className="sort-by" onClick={this.sortByKey('title')}>Name</th>
                      <th className="sort-by" onClick={this.sortByKey('id')}>ID</th>
                      <th className="sort-by" onClick={this.sortByKey('mau_count')}>MAU</th>
                      <th className="sort-by" onClick={this.sortByKey('platform')}>Platform</th>
                      <th className="sort-by" onClick={this.sortByKey('store_id')}>Store ID</th>
                      <th className="sort-by" onClick={this.sortByKey('created_at')}>Created</th>
                      <th className="sort-by" onClick={this.sortByKey('went_live_on')}>Went Live</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data && this.state.data.map((app) => (
                      <tr key={app.id}>
                        <td><Link to={`/apps/${app.id}`}>{app.title}</Link></td>
                        <td>{app.id}</td>
                        <td>{app && app.mau_count && app.mau_count.toLocaleString()}</td>
                        <td>{app.platform}</td>
                        <td>{storeFormat(app.store_id, app.platform)}</td>
                        <td>{dateFormat(app.created_at)}</td>
                        <td>{dateFormat(app.went_live_on)}</td>
                        <td>
                          <button type="button" className="button is-small" onClick={this.favorite(app.id)}>Favorite</button>
                        </td>
                      </tr>))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppsList;
