import React from 'react';

const storeFormat = (id, platform, region) => {
  if (!platform) {
    return id || null;
  }

  if (platform.toLowerCase() === 'ios') {
    return (
      <a
        href={`https://itunes.apple.com/${region || 'us'}/app/apptentive/id${id}`}
        rel="noopener noreferrer"
        target="new"
        title="Open in iTunes Store"
      >
        {id}
      </a>
    );
  }

  if (platform.toLowerCase() === 'android') {
    return (
      <a
        href={`https://play.google.com/store/apps/details?id=${id}&hl=${region || 'en_US'}`}
        rel="noopener noreferrer"
        target="new"
        title="Open in Google Play Store"
      >
        {id}
      </a>
    );
  }

  return id || null;
};

export default storeFormat;
