import React, { Component } from 'react';
import JWT from 'utils/jwt';
import * as api from 'utils/api';
import Favorites from 'components/shared/favorites';
import { atriumBaseUrl, baseUrl } from '../resources';
import { BE_APPTENTIVE_LINKS } from '../utils/helpers';

class AdminNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favoritesOpen: false,
      menuOpen: false,
    };

    this.reset = this.reset.bind(this);
    this.logout = this.logout.bind(this);
    this.toggleFavorites = this.toggleFavorites.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  async logout() {
    try {
      await api.del(`${atriumBaseUrl}/logout`);
      this.reset();
    } catch (error) {
      console.error('Logout Error:', error);
      this.reset();
    }
  }

  reset() {
    JWT.clearLocalStorage();
    window.location.assign('/login');
  }

  toggleFavorites() {
    this.setState({ favoritesOpen: !this.state.favoritesOpen });
  }

  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  render() {
    return (
      <nav className="navbar is-white">
        <div className="container">
          <div className="navbar-brand">
            <a className="navbar-item brand-text" href="/">
              Apptentive Admin Portal
            </a>
            <div
              className={`navbar-burger burger ${this.state.menuOpen ? 'is-active' : ''}`}
              data-target="navMenu"
              role="button"
              tabIndex={0}
              onClick={this.toggleMenu}
              onKeyPress={this.toggleMenu}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className={`navbar-menu ${this.state.menuOpen ? 'is-active' : ''}`}>
            <div className="navbar-start">
              <a
                href={`${baseUrl}/admin/sidekiq`}
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-item"
                title="Background Jobs / Sidekiq"
              >
                Background Jobs
              </a>
              <a href="/rollout" className="navbar-item" title="Feature Flipper / Rollout">
                Feature Flipper
              </a>
              <a href="/import-app" className="navbar-item" title="Import App / Rollout">
                Import App
              </a>
              <a href="/register-user" className="navbar-item" title="Register User">
                Register User
              </a>
              <a
                href={`https://${BE_APPTENTIVE_LINKS[process.env.NODE_ENV]}/invitation/resend`}
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-item"
                title="Invite Resend"
              >
                Invite Resend
              </a>
              <a href="/data-management" className="navbar-item" title="Data Management">
                Data Management
              </a>
              <a href="/keys" className="navbar-item" title="API Keys">
                API Keys
              </a>
            </div>
            <div className="navbar-end">
              <div
                className={`navbar-item has-dropdown ${this.state.favoritesOpen ? 'is-active' : ''}`}
                data-target="favorites"
                role="button"
                tabIndex={0}
                onClick={this.toggleFavorites}
                onKeyPress={this.toggleFavorites}
              >
                <div className="navbar-link">Favorites</div>
                <div className="navbar-dropdown favorites" id="favorites">
                  <Favorites />
                </div>
              </div>
              <div className="navbar-item">
                <button className="button is-small" onClick={this.logout}>
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default AdminNav;
