import React from 'react';

// Types: primary, link, info, success, warning, danger
const Notification = ({
  content = [],
  showDelete = true,
  type = 'info',
  dismiss = () => {},
}) => (
  <div className={`notification is-${type}`}>
    {showDelete ? <button className="delete" onClick={dismiss}>&nbsp;</button> : null}
    {content.map(text => <p key={text}>{text}</p>)}
  </div>
);

export default Notification;
