import React from 'react';
import { Manager, Popper } from 'react-popper';
import onClickOutside from 'react-onclickoutside';
import Mousetrap from 'mousetrap';
import { bemPrefix } from '../../../utils/bem';
import './popover.scss';

const PopoverPlacement = {
  top: 'top',
  topStart: 'top-start',
  topEnd: 'top-end',
  bottom: 'bottom',
  bottomStart: 'bottom-start',
  bottomEnd: 'bottom-end',
};

const bem = bemPrefix('popover');

export class Popover extends React.PureComponent {
  static PopoverPlacement = PopoverPlacement;

  mousetrap = new Mousetrap();

  componentDidMount() {
    this.mousetrap.bind(['esc'], this.handleClickOutside);
  }

  componentWillUnmount() {
    this.mousetrap.unbind(['esc']);
  }

  handleClickOutside = (event) => {
    if (['keydown', 'keypress'].includes(event.type) && event.key === 'Escape') {
      this.props.onClose(event);
      return;
    }
    if (this.props.targetEl && this.props.targetEl.contains(event.target)) {
      return;
    }
    this.props.onClose(event);
  };

  render() {
    if (!this.props.isOpen) {
      return null;
    }
    const { className, placement, targetEl, innerRef, children } = this.props;
    return (
      <Manager>
        <Popper
          placement={placement}
          referenceElement={targetEl || undefined}
          innerRef={innerRef}
          eventsEnabled={false}
        >
          {({ ref, style }) => (
            <div className={`${className} ${bem('body', placement)}`} ref={ref} style={style}>
              {children}
            </div>
          )}
        </Popper>
      </Manager>
    );
  }
}

Popover.defaultProps = {
  className: '',
  placement: PopoverPlacement.bottom,
  eventsEnabled: false,
  onClose: () => null,
};

export const PopoverContainer = onClickOutside(Popover);
