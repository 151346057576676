import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { sortBy, prop, reverse } from 'ramda';
import { adminBaseUrl } from 'resources/index';
import * as api from 'utils/api';
import dateFormat from 'utils/date_format';

class CollaboratorsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      hasFetched: false,
      sortKey: 'name',
      sortReverse: false,
    };

    this.fetch = this.fetch.bind(this);
    this.sortByKey = this.sortByKey.bind(this);
  }

  componentDidMount() {
    this.fetch(this.props.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.id && !this.state.hasFetched) {
      this.fetch(nextProps.id);
    }
  }

  async fetch(id) {
    const response = await api.get(`${adminBaseUrl}/organizations/${id}/collaborators`);
    const data = await response.json();
    if (data && Array.isArray(data)) {
      this.setState({
        data,
        hasFetched: true,
      });
    } else if (data && data.error) {
      console.error(data.error);
    }
  }

  sortByKey(sortKey) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      let sortReverse = false;
      let data = sortBy(prop(sortKey), this.state.data);
      if (sortKey === this.state.sortKey && this.state.sortReverse === false) {
        sortReverse = true;
        data = reverse(data);
      }
      this.setState({ sortKey, data, sortReverse });
    };
  }

  render() {
    return (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Collaborators</p>
            </header>
            <div className="card-table">
              <div className="content">
                <table className="table is-fullwidth is-striped is-bordered">
                  <thead>
                    <tr>
                      <th className="sort-by" onClick={this.sortByKey('name')}>Name</th>
                      <th className="sort-by" onClick={this.sortByKey('email')}>Email</th>
                      <th className="sort-by" onClick={this.sortByKey('professional_role')}>Professional Role</th>
                      <th className="sort-by" onClick={this.sortByKey('id')}>ID</th>
                      <th>Last Sign In</th>
                      <th className="sort-by" onClick={this.sortByKey('created_at')}>Created At</th>
                      <th className="sort-by" onClick={this.sortByKey('invitation_sent_at')}>Invitation Sent At</th>
                      <th>Invited By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((collaborator) => (
                      <tr key={collaborator.id}>
                        <td><Link to={`/users/${collaborator.id}`}>{collaborator.name}</Link></td>
                        <td>{collaborator.email}</td>
                        <td>{collaborator.professional_role}</td>
                        <td>{collaborator.id}</td>
                        <td>{dateFormat(collaborator.current_sign_in_at)}</td>
                        <td>{dateFormat(collaborator.created_at)}</td>
                        <td>{dateFormat(collaborator.invitation_sent_at)}</td>
                        <td>
                          {
                            collaborator.invited_by ?
                              <Link to={`/users/${collaborator.invited_by.id}`}>{collaborator.invited_by.name}</Link> : null
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CollaboratorsList;
