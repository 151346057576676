import React from 'react';
import PropTypes from 'prop-types';
import Day from './day';

export function daysOfMonthBuilder(displayedMonth) {
  const results = [];
  const startAt = -displayedMonth.day() % 7;
  const endAt = Math.ceil((displayedMonth.daysInMonth() - startAt) / 7) * 7 + startAt - 1; // eslint-disable-line

  let i = startAt;
  let d = startAt;
  for (i = startAt; startAt <= endAt ? i <= endAt : i >= endAt; d = startAt <= endAt ? ++i : --i) { // eslint-disable-line
    results.push(displayedMonth.clone().add(d, 'days'));
  }

  return results;
}

export function weekEnumBuilder(daysOfMonth) {
  const results = [];

  let i;
  const ref = daysOfMonth.length / 7;
  for (i = 0; ref >= 0 ? i < ref : i > ref; ref >= 0 ? i++ : i--) { // eslint-disable-line
    results.push(i);
  }
  return results;
}

export function isDateFromNextMonth(date, displayedMonth) {
  return date.year() > displayedMonth.year() || (date.year() === displayedMonth.year() && date.month() > displayedMonth.month());
}

export function isDateFromPrevMonth(date, displayedMonth) {
  return date.year() < displayedMonth.year() || (date.year() === displayedMonth.year() && date.month() < displayedMonth.month());
}

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayedMonth: props.date.clone().startOf('month'),
    };

    this.moveDisplayedMonth = this.moveDisplayedMonth.bind(this);
    this.selectDay = this.selectDay.bind(this);
  }

  selectDay(date) {
    this.props.selectDay(date);
  }

  moveDisplayedMonth(delta) {
    return () => {
      const dm = this.state.displayedMonth;
      this.setState({
        displayedMonth: dm.clone().add(delta, 'months'),
      });
    };
  }

  renderNameOfDays() {
    const nameOfDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return (
      <tr className="tdp-calendar-day-names">
        {nameOfDays.map((day) => <td key={`${day}`} aria-label={day}>{day}</td>)}
      </tr>
    );
  }

  renderDay(daysOfMonth, week) {
    const days = [0, 1, 2, 3, 4, 5, 6];
    const { minDate, maxDate } = this.props;

    return days.map((day) => {
      const date = daysOfMonth[(week * 7) + day];
      let disabled;

      if (minDate && maxDate) {
        disabled = date.isBefore(minDate, 'day') || date.isAfter(maxDate, 'day');
      } else if (minDate) {
        disabled = date.isBefore(minDate, 'day');
      } else if (maxDate) {
        disabled = date.isAfter(maxDate, 'day');
      }

      const dayNextMonth = isDateFromNextMonth(date, this.state.displayedMonth);
      const dayPrevMonth = isDateFromPrevMonth(date, this.state.displayedMonth);

      return (
        <Day
          key={`${date}`}
          day={date}
          selectDay={this.selectDay}
          disabled={disabled}
          dayPrevMonth={dayPrevMonth}
          dayNextMonth={dayNextMonth}
          active={this.props.date}
        />
      );
    });
  }

  renderWeeks() {
    const daysOfMonth = daysOfMonthBuilder(this.state.displayedMonth);
    const weekEnum = weekEnumBuilder(daysOfMonth);

    return weekEnum.map((week) => (
      <tr key={week}>
        {this.renderDay(daysOfMonth, week)}
      </tr>
    ));
  }

  render() {
    const { previousMonthText, nextMonthText } = this.props;
    return (
      <div className="tdp-calendar-container">
        <table className="tdp-calendar" cellPadding="0" cellSpacing="0" ref={table => { this.table = table; }}>
          <thead>
            <tr role="presentation">
              <th className="tdp-calendar-prev-month">
                <button onClick={this.moveDisplayedMonth(-1)} aria-label="Previous Month">{previousMonthText}</button>
              </th>
              <th className="tdp-calendar-current-month" colSpan="5" aria-label="Current Month">
                {this.state.displayedMonth.format('MMMM YYYY')}
              </th>
              <th className="tdp-calendar-next-month">
                <button onClick={this.moveDisplayedMonth(1)} aria-label="Next Month">{nextMonthText}</button>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.renderNameOfDays()}
            {this.renderWeeks()}
          </tbody>
        </table>
      </div>
    );
  }
}

Calendar.defaultProps = {
  previousMonthText: '<',
  nextMonthText: '>',
};

Calendar.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Date),
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Date),
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Date),
  ]),
  selectDay: PropTypes.func,
  previousMonthText: PropTypes.string,
  nextMonthText: PropTypes.string,
};
