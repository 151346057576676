/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { pluck, uniq, without } from 'ramda';
import { adminBaseUrl } from 'resources/index';
import * as api from 'utils/api';
import Notification from 'shared/notification';
import TokenInput from 'shared/token-input';

class EntitlementEditForm extends Component {
  constructor(props) {
    super(props);

    this.avaliable_features = [
      { value: 'allow_hide_branding', name: 'allow_hide_branding' },
      { value: 'apptimize_integration', name: 'apptimize_integration' },
      { value: 'competitors', name: 'competitors' },
      { value: 'conversations_export', name: 'conversations_export' },
      { value: 'create_new_app_org', name: 'create_new_app_org' },
      { value: 'csv_exports', name: 'csv_exports' },
      { value: 'custom_banner', name: 'custom_banner' },
      { value: 'desk_hook_active', name: 'desk_hook_active' },
      { value: 'developer_console', name: 'developer_console' },
      { value: 'disable_message_center', name: 'disable_message_center' },
      { value: 'download_center', name: 'download_center' },
      { value: 'ekg_edit_topics', name: 'ekg_edit_topics' },
      { value: 'ekg_reviews', name: 'ekg_reviews' },
      { value: 'ekg_topics', name: 'ekg_topics' },
      { value: 'enhanced_dashboard', name: 'enhanced_dashboard' },
      { value: 'fan_signals_graphs', name: 'fan_signals_graphs' },
      { value: 'github_integration', name: 'github_integration' },
      { value: 'groups', name: 'groups' },
      { value: 'insights_download', name: 'insights_download' },
      { value: 'integrations', name: 'integrations' },
      { value: 'interaction_response_targeting', name: 'interaction_response_targeting' },
      { value: 'message_insights', name: 'message_insights' },
      { value: 'new_in_app_review', name: 'new_in_app_review' },
      { value: 'notifications', name: 'notifications' },
      { value: 'parature_integration', name: 'parature_integration' },
      { value: 'phrase_explorer', name: 'phrase_explorer' },
      { value: 'power_user', name: 'power_user' },
      { value: 'rating_prompt_decoupling', name: 'rating_prompt_decoupling' },
      { value: 'sentiment', name: 'sentiment' },
      { value: 'slack_integration', name: 'slack_integration' },
      { value: 'survey_reports', name: 'survey_reports' },
      { value: 'translation', name: 'translation' },
      { value: 'user_study_list', name: 'user_study_list' },
      { value: 'voice_of_customer_preview', name: 'voice_of_customer_preview' },
      { value: 'voice_of_customer', name: 'voice_of_customer' },
      { value: 'web_sdk', name: 'web_sdk' },
    ];

    const entitlement = this.props.entitlements.find((ent) => ent.id === this.props.entitlementId);
    let selected = [];
    if (entitlement && entitlement.added_features) {
      selected = entitlement.added_features.map((feature) => ({ name: feature, value: feature }));
    }

    this.state = {
      errors: [],
      options: [...this.avaliable_features],
      selected,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissErrors = this.dismissErrors.bind(this);
    this.updateOptions = this.updateOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  componentDidMount() {
    document.title = `Editing Entitlement ${this.props.entitlementId}`;
    this.updateOptions();
  }

  async handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.tier.value) {
      this.setState({
        errors: ['Entitlement tier is required.', ...this.state.errors],
      });
      return;
    }
    if (!this.term.value) {
      this.setState({
        errors: ['Entitlement term is required.', ...this.state.errors],
      });
      return;
    }
    if (!this.started_on.value) {
      this.setState({
        errors: ['Entitlement started on date is required.', ...this.state.errors],
      });
      return;
    }

    const body = {
      started_on: this.started_on.value,
      term: this.term.value,
      tier: this.tier.value,
    };
    if (this.mau_limit.value) {
      body.mau_limit = this.mau_limit.value;
    }
    if (this.collaborator_limit.value) {
      body.collaborator_limit = this.collaborator_limit.value;
    }
    if (this.interaction_limit.value) {
      body.interaction_limit = this.interaction_limit.value;
    }
    if (this.app_limit.value) {
      body.app_limit = this.app_limit.value;
    }
    if (this.ended_on.value) {
      body.ended_on = this.ended_on.value;
    }
    if (this.state.selected) {
      body.added_features = pluck('value', this.state.selected);
    }

    const apiCaller = this.props.mode === 'edit' ? api.put : api.post;
    const url = `${adminBaseUrl}/organizations/${this.props.orgId}/entitlements${
      this.props.mode === 'edit' ? `/${this.props.entitlementId}` : ''
    }`;
    const response = await apiCaller(url, body);
    const data = await response.json();
    if (data && data.error) {
      this.setState({
        errors: [`Failed to update Organization Entitlement: ${data.error}`, ...this.state.errors],
      });
    } else {
      window.location.assign(`/organizations/${this.props.orgId}`);
    }
  }

  handleChange(selected) {
    this.setState({ selected }, () => {
      this.updateOptions();
    });
  }

  handleRemove(value, option) {
    const selected = uniq(without([option], this.state.selected));
    this.handleChange(selected);
  }

  handleSelect(value, option) {
    if (!option) {
      option = { value, name: value };
    }

    const selected = uniq(this.state.selected.concat([option]));
    this.handleChange(selected);
  }

  handleInput(userInput) {
    this.updateOptions();
    if (!userInput || userInput === '') {
      return;
    }
    const filter = new RegExp(`^${userInput}`, 'i');
    const options = this.state.options.filter((feature) => filter.test(feature.name));

    this.setState({ options });
  }

  updateOptions() {
    this.setState({ options: uniq(without(this.state.selected, this.avaliable_features)) });
  }

  dismissErrors(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      errors: [],
    });
  }

  render() {
    let entitlement = this.props.entitlements.find((ent) => ent.id === this.props.entitlementId);
    if (!entitlement) {
      entitlement = {
        tier: null,
        term: null,
        mau_limit: null,
        collaborator_limit: null,
        interaction_limit: null,
        app_limit: null,
        started_on: null,
        ended_on: null,
        created_at: null,
        active: null,
        future: null,
      };
    }
    return (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Edit Organization Entitlement</p>
            </header>
            <div className="card-content">
              <div className="content">
                {this.state.errors.length ? (
                  <Notification type="danger" content={this.state.errors} dismiss={this.dismissErrors} />
                ) : null}
                <form acceptCharset="UTF-8" className="organization" id="organization" onSubmit={this.handleSubmit}>
                  <div className="field">
                    <label className="label" htmlFor="tier">
                      Tier
                      <div className="control">
                        <div className="select">
                          <select
                            disabled={this.props.mode === 'view' || this.props.mode === 'edit'}
                            name="tier"
                            id="tier"
                            defaultValue={entitlement.tier}
                            required
                            ref={(input) => (this.tier = input)}
                          >
                            <option disabled>Select User...</option>
                            <option value="internal">Internal</option>
                            <option value="self_serve_trial">Self Serve Trial</option>
                            <option value="support">Support</option>
                            <option value="learn">Learn</option>
                            <option value="grow">Grow</option>
                            <option value="enterprise">Enterprise</option>
                            <option value="managed_enterprise">Managed Enterprise</option>
                            <option value="enterprise_trial">Enterprise Trial</option>
                          </select>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="term">
                      Term
                      <div className="control">
                        <div className="select">
                          <select
                            disabled={this.props.mode === 'view' || this.props.mode === 'edit'}
                            name="term"
                            id="term"
                            defaultValue={entitlement.term}
                            required
                            ref={(input) => (this.term = input)}
                          >
                            <option disabled>Select Term...</option>
                            <option value="monthly">Monthly</option>
                            <option value="annual">Annual</option>
                          </select>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="mau_limit">
                      MAU Limit
                      <div className="control">
                        <input
                          disabled={this.props.mode === 'view'}
                          className="input"
                          type="number"
                          placeholder="MAU Limit"
                          name="mau_limit"
                          defaultValue={entitlement.mau_limit}
                          ref={(input) => (this.mau_limit = input)}
                        />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="collaborator_limit">
                      Collaborator Limit
                      <div className="control">
                        <input
                          disabled={this.props.mode === 'view'}
                          className="input"
                          type="number"
                          placeholder="Collaborator Limit"
                          name="collaborator_limit"
                          defaultValue={entitlement.collaborator_limit}
                          ref={(input) => (this.collaborator_limit = input)}
                        />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="interaction_limit">
                      Interaction Limit
                      <div className="control">
                        <input
                          disabled={this.props.mode === 'view'}
                          className="input"
                          type="number"
                          placeholder="Interaction Limit"
                          name="interaction_limit"
                          defaultValue={entitlement.interaction_limit}
                          ref={(input) => (this.interaction_limit = input)}
                        />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="app_limit">
                      App Limit
                      <div className="control">
                        <input
                          disabled={this.props.mode === 'view'}
                          className="input"
                          type="number"
                          placeholder="App Limit"
                          name="app_limit"
                          defaultValue={entitlement.app_limit}
                          ref={(input) => (this.app_limit = input)}
                        />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="started_on">
                      Started On
                      <div className="control">
                        <input
                          disabled={this.props.mode === 'view'}
                          className="input"
                          type="date"
                          maxLength="10"
                          size="10"
                          step="1"
                          placeholder="Started On"
                          name="started_on"
                          defaultValue={entitlement.started_on}
                          required
                          ref={(input) => (this.started_on = input)}
                        />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="ended_on">
                      Ending On
                      <div className="control">
                        <input
                          disabled={this.props.mode === 'view'}
                          className="input"
                          type="date"
                          maxLength="10"
                          size="10"
                          step="1"
                          placeholder="Ended On"
                          name="ended_on"
                          defaultValue={entitlement.ended_on}
                          ref={(input) => (this.ended_on = input)}
                        />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="added_features">
                      Added Features
                      <div className="control">
                        <TokenInput
                          id="added_features"
                          name="added_features"
                          isDisabled={this.props.mode === 'view'}
                          menuContent={this.state.options}
                          onChange={this.handleChange}
                          onInput={this.handleInput}
                          onRemove={this.handleRemove}
                          onSelect={this.handleSelect}
                          placeholder="Enter Features here..."
                          selected={this.state.selected}
                        />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <div className="control">
                      {this.props.mode === 'new' || this.props.mode === 'edit' ? (
                        <button className="button is-link">
                          {this.props.mode === 'edit' ? 'Update Entitlement' : 'Create Entitlement'}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EntitlementEditForm.defaultProps = {
  entitlements: [],
  entitlementId: null,
  mode: 'view',
  orgId: null,
};

export default EntitlementEditForm;
