import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as api from 'utils/api';
import Loading from 'shared/loading';
import OrganizationAppMigrationForm from 'components/organizations/organization_app_migration_form';
import { adminBaseUrl } from 'resources';

class OrganizationAppMigration extends Component {
  constructor(params) {
    super(params);
    this.state = {
      organization: {
        name: '',
        id: '',
      },
      hasFetched: false,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    document.title = 'Organization App Migration';
    this.fetch(this.props.match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.match.params.id && !this.state.hasFetched) {
      this.fetch(nextProps.id);
    }
  }

  async fetch(id) {
    const response = await api.get(`${adminBaseUrl}/organizations/${id}`);
    const data = await response.json();
    this.setState({
      organization: data,
      hasFetched: true,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={`/organizations/${this.props.match.params.id}`}>Org: {this.props.match.params.id}</Link>
                </li>
                <li className="is-active">
                  <Link to={`/organizations/${this.props.match.params.id}/edit`} aria-current="page">Migrating App for Org: {this.props.match.params.id}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {this.state.hasFetched ? <OrganizationAppMigrationForm organization={this.state.organization} /> : <Loading text="Loading..." />}
      </div>
    );
  }
}

export default OrganizationAppMigration;
