import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Calendar from './calendar';

export default class SimpleDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: dayjs(props.date).startOf('day'),
      isCalendarOpen: false,
    };

    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', this.onClickOutside);
      document.addEventListener('touchstart', this.onClickOutside);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('mousedown', this.onClickOutside);
      document.removeEventListener('touchstart', this.onClickOutside);
    }
  }

  onClickOutside(event) {
    if (!this.calendar || !this.calendar.table) {
      return;
    }

    if (!this.calendar.table.contains(event.target) && !this.dateInput.contains(event.target)) {
      this.setState({ isCalendarOpen: false });
    }
  }

  toggleCalendar() {
    this.setState({ isCalendarOpen: !this.state.isCalendarOpen });
  }

  selectDay(date) {
    const { clickOnDate, name } = this.props;

    this.setState({
      isCalendarOpen: false,
      date,
    });

    if (clickOnDate) {
      clickOnDate(date, name);
    }
  }

  renderCalendar() {
    if (!this.state.isCalendarOpen) {
      return null;
    }

    const {
      minDate, maxDate,
      previousMonthText, nextMonthText,
    } = this.props;
    return (
      <Calendar
        ref={calendar => { this.calendar = calendar; }}
        date={this.state.date}
        minDate={minDate}
        maxDate={maxDate}
        selectDay={this.selectDay}
        previousMonthText={previousMonthText}
        nextMonthText={nextMonthText}
      />);
  }

  render() {
    return (
      <div
        aria-label="Calendar View Date-Picker"
        className="tdp-date-picker"
        ref={dateInput => { this.dateInput = dateInput; }}
      >
        <input
          type="text"
          className="tdp-input"
          value={this.state.date.format('YYYY-MM-DD')}
          onClick={this.toggleCalendar}
          readOnly
        />
        {this.renderCalendar()}
      </div>
    );
  }
}

SimpleDatePicker.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Date),
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Date),
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Date),
  ]),
  clickOnDate: PropTypes.func,
  name: PropTypes.string,
  previousMonthText: PropTypes.string,
  nextMonthText: PropTypes.string,
};

SimpleDatePicker.defaultProps = {
  date: new Date(),
};
