import React from 'react';
import Lock from 'utils/lock_config';
import JWT from 'utils/jwt';

class Login extends React.Component {
  componentDidMount() {
    if (JWT.valid()) {
      window.location.assign('/loading');
    } else {
      Lock.show();
    }
  }

  render() {
    return null;
  }
}

export default Login;
