import { GROUP_LABEL, REQUEST_LABEL, ACTIVITY_LABEL } from './gdpr-types';

export const handleDate = (dateToParse) =>
  new Date(dateToParse).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

export const getType = (reqType) => REQUEST_LABEL[reqType.toLowerCase()] || '';

export const getGroup = (identity) => GROUP_LABEL[identity.toLowerCase()] || '';

export const getIdentities = (identities) =>
  (identities['apptentive.com'] && identities['apptentive.com'].identity_value
    ? identities['apptentive.com'].identity_value.join()
    : '');

const getExportNameValues = (identities) => {
  if (identities['apptentive.com'] && identities['apptentive.com'].identity_value) {
    const items = identities['apptentive.com'].identity_value;
    return items.length > 2 ? `${items[0]},${items[1]},...` : items.join();
  }
  return '';
};

export const getActivity = (activity) => ACTIVITY_LABEL[activity.toLowerCase()] || '';

const standatizeDate = (date) => (date > 9 ? date : `0${date}`);

export const getExportFileName = (request) => {
  const introOld = request.org_name ? request.org_name : getExportNameValues(request.extensions);
  const date = new Date(request.date);
  const day = standatizeDate(new Date(date).getDate());
  const mounth = standatizeDate(new Date(date).getMonth() + 1);
  const parsedDate = `${day}-${mounth}-${new Date(date).getFullYear()}`;
  return `${introOld}_${getType(request.process)}_${parsedDate}`;
};

export const getBucketFileNameFromUrl = (url) => {
  let awsHost;
  let bucketName;
  let userFolderOrKey;
  let fileName;
  const isOldS3 = url.split('//')[1].startsWith('s3.');

  if (isOldS3) {
    [, bucketName, userFolderOrKey, fileName] = url.split('//')[1].split('/');
  } else {
    [awsHost, userFolderOrKey, fileName] = url.split('//')[1].split('/');
    bucketName = awsHost.split('.')[0];
  }
  return {
    bucketName,
    fileKey: fileName ? `${userFolderOrKey}/${fileName}` : userFolderOrKey,
  };
};

export const BLOCK_SEARCH_FOR_CID_FLAG = true;
