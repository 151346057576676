import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { sortBy, prop, reverse } from 'ramda';
import * as api from 'utils/api';
import dateFormat from 'utils/date_format';
import { adminBaseUrl } from 'resources';
import Notification from 'shared/notification';

export function isLastAdmin(data) {
  if (data.filter((member) => member.role === 'admin').length > 1) {
    return false;
  }
  return true;
}

class AppMemberList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      errors: [],
      hasFetched: false,
      sortKey: 'name',
      sortReverse: false,
    };

    this.fetch = this.fetch.bind(this);
    this.sortByKey = this.sortByKey.bind(this);
    this.favoriteUser = this.favoriteUser.bind(this);
    this.handleChangeRole = this.handleChangeRole.bind(this);
    this.removeAppMember = this.removeAppMember.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissErrors = this.dismissErrors.bind(this);
  }

  componentDidMount() {
    if (this.props.app && this.props.app.id) {
      this.fetch(this.props.app.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.app && nextProps.app.id && !this.state.hasFetched) {
      this.fetch(nextProps.app.id);
    }
  }

  handleChangeRole(appId, appMemberId, currentRole) {
    return (_event) => {
      const role = _event?.target?.value || '';
      if (window.confirm(`Change user role from ${currentRole} to ${role}?`)) {
        try {
          api.put(`${adminBaseUrl}/apps/${appId}/app_members/${appMemberId}`, { role });
        } catch (error) {
          this.setState({
            errors: [`Error Chaning App Member Role: ${error}`, ...this.state.errors],
          });
        }
      }
    };
  }

  async handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.member_email.value) {
      this.setState({
        errors: ['Email is required.', ...this.state.errors],
      });
      return;
    }
    if (!this.member_name.value) {
      this.setState({
        errors: ['Name is required.', ...this.state.errors],
      });
      return;
    }
    if (!this.member_role.value) {
      this.setState({
        errors: ['Role is required.', ...this.state.errors],
      });
      return;
    }

    let data;
    try {
      const response = await api.post(`${adminBaseUrl}/apps/${this.props.app.id}/app_members`, {
        invite_email: this.member_email.value,
        name: this.member_name.value,
        role: this.member_role.value,
      });
      data = await response.json();
    } catch (error) {
      this.setState({
        errors: [`Error Adding App Member: ${error}`, ...this.state.errors],
      });
      return;
    }
    if (data && data.error) {
      this.setState({
        errors: [`Failed to add member: ${data.error}`, ...this.state.errors],
      });
    } else {
      window.location.reload();
    }
  }

  async fetch(id) {
    let data;
    try {
      const response = await api.get(`${adminBaseUrl}/apps/${id}/app_members`);
      data = await response.json();
    } catch (error) {
      this.setState({
        errors: [`Error Fetching App Members: ${error}`, ...this.state.errors],
      });
      return;
    }
    this.setState({
      data,
      hasFetched: true,
      isLastAdmin: isLastAdmin(data),
    });
  }

  sortByKey(sortKey) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      let sortReverse = false;
      let data = sortBy(prop(sortKey), this.state.data);
      if (sortKey === this.state.sortKey && this.state.sortReverse === false) {
        sortReverse = true;
        data = reverse(data);
      }
      this.setState({ sortKey, data, sortReverse });
    };
  }

  favoriteUser(id) {
    return (_event) => {
      try {
        api.post(`${adminBaseUrl}/favorites?id=${id}&type=users`);
      } catch (error) {
        this.setState({
          errors: [`Error Favoriting User: ${error}`, ...this.state.errors],
        });
      }
    };
  }

  dismissErrors(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      errors: [],
    });
  }

  removeAppMember(appId, appMemberId) {
    return (_event) => {
      if (window.confirm('Are you sure you want to remove this membership?')) { // eslint-disable-line no-alert
        try {
          api.del(`${adminBaseUrl}/apps/${appId}/app_members/${appMemberId}`);
        } catch (error) {
          this.setState({
            errors: [`Error Removing App Member "${appMemberId}": ${error}`, ...this.state.errors],
          });
        }
      }
    };
  }

  render() {
    return (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">App Members</p>
            </header>
            <div className="card-table">
              <div className="content">
                <table className="table is-fullwidth is-striped is-bordered">
                  <thead>
                    <tr>
                      <th className="sort-by" onClick={this.sortByKey('name')}>Name</th>
                      <th className="sort-by" onClick={this.sortByKey('email')}>Email</th>
                      <th className="sort-by" onClick={this.sortByKey('role')}>Role</th>
                      <th className="sort-by" onClick={this.sortByKey('user_id')}>User ID</th>
                      <th className="sort-by" onClick={this.sortByKey('last_sign_in_at')}>Last Sign In</th>
                      <th className="sort-by" onClick={this.sortByKey('created_at')}>Created At</th>
                      <th className="sort-by" onClick={this.sortByKey('invited')}>Invited?</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((member) => (
                      <tr className="member-row" key={member.id}>
                        <td><Link to={`/users/${member.user_id}`}>{member.name}</Link></td>
                        <td>{member.email}</td>
                        <td>
                          <select
                            className="user-role"
                            value={member.role}
                            onChange={this.handleChangeRole(this.props.app.id, member.id, member.role)}
                            disabled={member.role === 'admin' && this.state.isLastAdmin}
                          >
                            <option value="collaborator">Collaborator</option>
                            <option value="admin">Admin</option>
                            <option value="reporter">Reporter</option>
                          </select>
                        </td>
                        <td>{member.user_id}</td>
                        <td>{dateFormat(member.current_sign_in_at)}</td>
                        <td>{dateFormat(member.created_at)}</td>
                        <td>{member.invited ? 'Yes' : 'No'}</td>
                        <td>
                          <button className="button favorite is-small" type="button" onClick={this.favoriteUser(member.user_id)}>Favorite</button>
                          &nbsp;
                          <button
                            className="button remove is-small"
                            type="button"
                            onClick={this.removeAppMember(this.props.app.id, member.id)}
                            disabled={member.role === 'admin' && this.state.isLastAdmin}
                          >
                            Remove from App
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">Add App Member</p>
            </header>
            <div className="card-table">
              <div className="card-content">
                {this.state.errors.length ? (
                  <Notification type="danger" content={this.state.errors} dismiss={this.dismissErrors} />
                ) : null}
                <form acceptCharset="UTF-8" className="organization" id="organization" onSubmit={this.handleSubmit}>
                  <div className="field">
                    <label className="label" htmlFor="name">
                      Name
                      <div className="control">
                        <input className="input" type="text" placeholder="Name" name="name" required ref={(input) => this.member_name = input} />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="email">
                      Email
                      <div className="control">
                        <input className="input" type="email" placeholder="Email" name="email" required ref={(input) => this.member_email = input} />
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="tier">
                      Role
                      <div className="control">
                        <div className="select">
                          <select name="role" id="role" required ref={(input) => (this.member_role = input)}>
                            <option value="collaborator">Collaborator</option>
                            <option value="admin">Admin</option>
                            <option value="reporter">Reporter</option>
                          </select>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button className="button is-link">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppMemberList;
