import React from 'react';
import PropTypes from 'prop-types';

class Token extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleClick() {
    this.props.onRemove(this.props.value, this.props.option);
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.props.onRemove(this.props.value, this.props.option);
    }
  }

  render() {
    return (
      <li className="token">
        <span className="token-label">{this.props.name}</span>
        <span
          role="button"
          onClick={this.handleClick}
          onFocus={this.props.onFocus}
          onKeyDown={this.handleKeyDown}
          aria-label={`Remove '${this.props.name}'`}
          className="token-delete-button"
          tabIndex={0}
        >✕
        </span>
      </li>
    );
  }
}

Token.propTypes = {
  name: PropTypes.string,
  onFocus: PropTypes.func,
  onRemove: PropTypes.func,
  option: PropTypes.object,
  value: PropTypes.string,
};

Token.defaultProps = {
  name: '',
  onFocus: () => {},
  onRemove: () => {},
  option: { name: '', value: '' },
  value: null,
};

export default Token;
