import React from 'react';
import { Link } from 'react-router-dom';
import keysApi from './keys-api';
import { bemPrefix } from '../../utils/bem';

const bem = bemPrefix('keys-list');

class KeysList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      organizationId: '',
      description: '',
      debug: false,
      rawScope: false,
      metricsScope: false,
      expScope: false,
      keysData: [],
    };

    this.handleUserIdChange = this.handleUserIdChange.bind(this);
    this.handleOrgIdChange = this.handleOrgIdChange.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleDebugChange = this.handleDebugChange.bind(this);
    this.handleRawApiScope = this.handleRawApiScope.bind(this);
    this.handleMetricApiScope = this.handleMetricApiScope.bind(this);
    this.handleExperimentalAPIScope = this.handleExperimentalAPIScope.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => this.fetchCurrentKeys()

  handleUserIdChange(event) {
    event.preventDefault();
    this.setState({ userId: event.target.value });
  }

  handleOrgIdChange(event) {
    event.preventDefault();
    this.setState({ organizationId: event.target.value });
  }

  handleDescription(event) {
    event.preventDefault();
    this.setState({ description: event.target.value });
  }

  handleDebugChange(event) {
    const { checked } = event.target;
    this.setState({ debug: checked });
  }

  handleRawApiScope(event) {
    const { checked } = event.target;
    this.setState({ rawScope: checked });
  }

  handleMetricApiScope(event) {
    const { checked } = event.target;
    this.setState({ metricsScope: checked });
  }

  handleExperimentalAPIScope(event) {
    const { checked } = event.target;
    this.setState({ expScope: checked });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let newKey;
    if (this.state.userId) {
      newKey = await keysApi.createKey(this.state.userId, this.state.description, 'user',
        this.state.debug,
        this.state.rawScope,
        this.state.metricsScope,
        this.state.expScope);
    } else {
      newKey = await keysApi.createKey(this.state.organizationId, this.state.description, 'org',
        this.state.debug,
        this.state.rawScope,
        this.state.metricsScope,
        this.state.expScope);
    }

    this.setState({
      keysData: [
        newKey,
        ...this.state.keysData,
      ],
    });
  }

  fetchCurrentKeys = async () => {
    const rows = await keysApi.fetchKeys();
    this.setState({ keysData: rows });
  }

  deleteRow = async (id) => {
    await keysApi.deleteKey(id);
    this.setState({
      keysData: this.state.keysData.filter((row) => row.full_key_name !== id),
    });
  }

  render() {
    return (
      <div className={bem('')}>
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">API Keys</p>
                </header>
                <div className="card-content">
                  <form
                    acceptCharset="UTF-8"
                    id="register-api-key"
                    className="register-api-key"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="field">
                      <label className="label" htmlFor="email">
                        User ID
                        <div className="control">
                          <input
                            onChange={this.handleUserIdChange}
                            placeholder="ex. 51fc6272da2185850c00006a"
                            className="input name"
                          />
                        </div>
                        <br />
                        Organization ID (not needed if you have user id)
                        <div className="control">
                          <input
                            onChange={this.handleOrgIdChange}
                            placeholder="ex. 51fc6272da2185850c00006a"
                            className="input name"
                          />
                        </div>
                        <br />
                        Description (optional)
                        <div className="control">
                          <input
                            onChange={this.handleDescription}
                            className="input name"
                          />
                        </div>
                        <br />
                        <div className="field checkbox">
                          <label className="checkbox" htmlFor="api-keys">
                            <input
                              name="raw-api-scope"
                              type="checkbox"
                              onChange={this.handleRawApiScope}
                            />
                            Raw Endpoints Scope
                          </label>
                        </div>
                        <br />
                        <div className="field checkbox">
                          <label className="checkbox" htmlFor="api-keys">
                            <input
                              name="metric-api-scope"
                              type="checkbox"
                              onChange={this.handleMetricApiScope}
                            />
                            Metrics Endpoints Scope
                          </label>
                        </div>
                        <br />
                        <div className="field checkbox">
                          <label className="checkbox" htmlFor="api-keys">
                            <input
                              name="experimental-api-scope"
                              type="checkbox"
                              onChange={this.handleExperimentalAPIScope}
                            />
                            Experimental Endpoints Scope
                          </label>
                        </div>
                        <br />
                        <div className="field checkbox">
                          <label className="checkbox" htmlFor="api-keys">
                            <input
                              name="debug-api-key"
                              type="checkbox"
                              onChange={this.handleDebugChange}
                            />
                            Debug (key will not be tracked by analytics engine)
                          </label>
                        </div>
                      </label>
                    </div>
                    <div className="field">
                      <div className="control">
                        <input
                          type="submit"
                          className="button is-primary"
                          value="Create API Key"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">User API keys</p>
              </header>
              <div className="card-content">
                <table className="table is-fullwidth is-striped is-bordered">
                  <thead>
                    <tr>
                      <th>User ID</th>
                      <th>Description</th>
                      <th>Key</th>
                      <th>Created At</th>
                      <th>Debug</th>
                      <th>R</th>
                      <th>M</th>
                      <th>E</th>
                      <th>Created By</th>
                      <th>Delete Key</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.keysData.filter((k) => k.key_type === 'user').map((k) => (
                      <tr key={k.key_id}>
                        <td><Link to={`/users/${k.target_id}`}>{k.target_id}</Link></td>
                        <td>{k.description}</td>
                        <td>{k.full_key_name}</td>
                        <td>{k.created_at}</td>
                        <td>{k.debug.toString()}</td>
                        <td>{k.scope_raw.toString()}</td>
                        <td>{k.scope_metric.toString()}</td>
                        <td>{k.scope_exp.toString()}</td>
                        <td>{k.created_by}</td>
                        <td>
                          <button
                            type="button"
                            className="button is-small"
                            onClick={() => this.deleteRow(k.full_key_name)}
                          >Revoke
                          </button>
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">Org-level API keys (global)</p>
              </header>
              <div className="card-content">
                <table className="table is-fullwidth is-striped is-bordered">
                  <thead>
                    <tr>
                      <th>Organization ID</th>
                      <th>Description</th>
                      <th>Key</th>
                      <th>Created At</th>
                      <th>Debug</th>
                      <th>R</th>
                      <th>M</th>
                      <th>E</th>
                      <th>Created By</th>
                      <th>Delete Key</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.keysData.filter((k) => k.key_type === 'org').map((k) => (
                      <tr key={k.key_id}>
                        <td><Link to={`/organizations/${k.target_id}`}>{k.target_id}</Link></td>
                        <td>{k.description}</td>
                        <td>{k.full_key_name}</td>
                        <td>{k.created_at}</td>
                        <td>{k.debug.toString()}</td>
                        <td>{k.scope_raw.toString()}</td>
                        <td>{k.scope_metric.toString()}</td>
                        <td>{k.scope_exp.toString()}</td>
                        <td>{k.created_by}</td>
                        <td>
                          <button
                            type="button"
                            className="button is-small"
                            onClick={() => this.deleteRow(k.full_key_name)}
                          >Revoke
                          </button>
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KeysList;
