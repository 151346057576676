import React, { useState } from 'react';
import { RolloutContextProvider, useRolloutContext } from './rollout-context';
import { RolloutModalContainer } from './rollout-modal';
import { Icon } from '../shared/icon/icon';
import { bemPrefix } from '../../utils/bem';

import './rollout.scss';
import { FeatureFlag } from './feature-flag';

const bem = bemPrefix('rollout-page');

const filterFeatureFlags = (items, filter) => items.filter(item => item.toLowerCase().includes(filter.toLowerCase()));

const RolloutPage = () => {
  const { featureFlags, createNewFlag } = useRolloutContext();
  const [openModal, setOpenModal] = useState(false);
  const [filter, setFilter] = useState('');

  const handleFilterChange = value => setFilter(value);

  const shownModal = () => {
    setOpenModal(true);
  };

  const clearFilter = () => setFilter('');

  const handleSubmit = ({ feature, groups, members, percentage }) => {
    setOpenModal(false);
    createNewFlag({ feature, groups, members, percentage });
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const filtered = filterFeatureFlags(featureFlags, filter);
  const reversedFeatureList = filtered.reverse();

  return (
    <div className={bem()}>
      <div className={bem('rollout-header')}>
        <span>Rollout</span>
        <div className="input-container">
          <input value={filter} onChange={(e) => handleFilterChange(e.target.value)} placeholder="Search" />
          <span className="close-button" onClick={clearFilter}>
            <Icon name="cancel" />
          </span>
        </div>
        <button onClick={shownModal}>New Feature</button>
      </div>
      {openModal && <RolloutModalContainer shownModal={openModal} submitHandler={handleSubmit} cancelHandler={closeModal} />}
      {reversedFeatureList.map((feature) => (
        <div key={`feature-${feature}`}>
          <FeatureFlag feature={feature} />
        </div>
      ))}
    </div>
  );
};

export const RolloutPageContainer = () => (
  <RolloutContextProvider>
    <RolloutPage />
  </RolloutContextProvider>
);
