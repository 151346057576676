import React from 'react';
import dayjs from 'dayjs';
import * as api from 'utils/api';
import { dataTransformTimeseries } from 'utils/transforms';
import dateFormat from 'utils/date_format';
import { __, append, ascend, compose, contains, descend, ifElse, prop, sortWith, toLower, without } from 'ramda';

import SimpleDatePicker from 'components/shared/date-picker';
import { atriumBaseUrl } from '../../resources';

class AppEventsTable extends React.Component {
  static prepareEvent(label = '') {
    if (!label) return '';
    if (/^com.apptentive#/i.test(label)) {
      label = label.replace(/^com.apptentive#/i, '').replace(/#/i, '.');
    }
    if (/^MessageCenter\./i.test(label)) {
      label = label.replace(/^MessageCenter\./i, 'message_center.');
    }
    if (/^EnjoymentDialog\./i.test(label)) {
      label = label.replace(/^EnjoymentDialog\./i, 'enjoyment_dialog.');
    }
    if (/^FeedbackDialog\./i.test(label)) {
      label = label.replace(/^FeedbackDialog\./i, 'feedback_dialog.');
    }
    if (/^RatingDialog\./i.test(label)) {
      label = label.replace(/^RatingDialog\./i, 'rating_dialog.');
    }
    if (/^TextModal\./i.test(label)) {
      label = label.replace(/^TextModal\./i, 'text_modal.');
    }
    if (/^About\./i.test(label)) {
      label = label.replace(/^About\./i, 'about.');
    }
    if (/^Survey\./i.test(label)) {
      label = label.replace(/^Survey\./i, 'survey.');
    }

    return label;
  }

  constructor(params) {
    super(params);
    this.state = {
      asc: true,
      sortKey: 'label',
      items: [],
      total: 0,
      ends_with: null,
      page: 0,
      limit: 100,
      has_more: false,
      eventsSelected: [],
      data: [],
      hasFetched: false,
      endDate: dayjs(new Date()).startOf('day'),
      startDate: dayjs(new Date()).startOf('day').subtract(30, 'days'),
    };

    this.fetchEventList = this.fetchEventList.bind(this);
    this.fetchEventStats = this.fetchEventStats.bind(this);
    this.sortEvents = this.sortEvents.bind(this);
    this.selectEvent = this.selectEvent.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    if (this.props.app && this.props.app.id) {
      this.fetchEventList(this.props.app.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.app && nextProps.app.id && !this.state.hasFetched) {
      this.fetchEventList(nextProps.app.id);
    }
  }

  sortEvents(newSortKey) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      let { asc, items } = this.state;
      if (this.state.sortKey === newSortKey) {
        asc = !asc;
      }
      let sorter;
      const direction = asc ? ascend : descend;
      if (newSortKey === 'label') {
        sorter = sortWith([
          direction(compose(toLower, prop(newSortKey))),
        ]);
      } else {
        sorter = sortWith([
          direction(prop(newSortKey)),
        ]);
      }
      items = sorter(items);
      this.setState({ asc, items, sortKey: newSortKey });
    };
  }

  async fetchEventList(id) {
    const start_date = this.state.startDate.unix();
    const end_date = this.state.endDate.unix();
    const starts_after = this.state.ends_with ? `&starts_after=${encodeURIComponent(this.state.ends_with)}` : '';
    const response = await api.get(`${atriumBaseUrl}/apps/${id}/events?start_date=${start_date}&end_date=${end_date}&sort_by=last_seen_at&sort_direction=desc&include_internal=true${starts_after}`);
    const data = await response.json();
    this.setState({
      ...data,
      items: [
        ...this.state.items,
        ...data.items,
      ],
      hasFetched: true,
    });
  }

  async fetchEventStats(id) {
    const { eventsSelected } = this.state;

    if (eventsSelected.length) {
      const events = eventsSelected.reduce((accumulator, currentValue) => `${accumulator}&label[]=${encodeURIComponent(AppEventsTable.prepareEvent(currentValue))}`, '');
      const start_date = this.state.startDate.format('YYYY-MM-DD');
      const end_date = this.state.endDate.format('YYYY-MM-DD');
      const url = `${atriumBaseUrl}/apps/${id}/event_stats?start_date=${start_date}&end_date=${end_date}&all_dates=true${events}`;
      const response = await api.get(url);
      const data = await response.json();
      const selected = eventsSelected.map(AppEventsTable.prepareEvent);
      this.setState({
        data: dataTransformTimeseries(selected, data),
        hasFetched: true,
      });
    }
  }

  selectEvent(label) {
    const toggleLabel = ifElse(
      contains(__, this.state.eventsSelected),
      without(__, this.state.eventsSelected),
      append(__, this.state.eventsSelected)
    );
    return () => {
      if (label) {
        this.setState({
          eventsSelected: toggleLabel(label),
        }, () => {
          this.fetchEventStats(this.props.app.id);
        });
      }
    };
  }

  selectDay(date, name) {
    if (!this.state[name].isSame(date)) {
      this.setState({ [name]: date }, () => {
        this.fetchEventList(this.props.app.id);
        this.fetchEventStats(this.props.app.id);
      });
    }
  }

  loadMore() {
    this.fetchEventList(this.props.app.id);
  }

  render() {
    return (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">App Events Table</p>
            </header>
            <div className="card-content">
              <div className="content">
                {
                  this.state.hasFetched ?
                    <section>
                      <table className="table table--interactive">
                        <thead>
                          <tr className="row row--action">
                            <td className="col col--action">
                              {/* <input placeholder="Search for Event Name" type="text" className="rbt-input-main" value="" /> */}
                            </td>
                            <td className="col col--action" />
                            <td className="col col--action">
                              {
                                this.state.has_more ?
                                  <div className="button-container">
                                    {(this.state.page * this.state.limit) + 1} - {this.state.items.length} of {this.state.total}
                                    <button className="button is-small" aria-label="Load More" onClick={this.loadMore}>Load More</button>
                                  </div> : null
                              }
                            </td>
                            <td className="col col--action">
                              Start Date:
                              <SimpleDatePicker
                                clickOnDate={this.selectDay}
                                date={this.state.startDate}
                                maxDate={new Date()}
                                name="startDate"
                              />
                            </td>
                            <td className="col col--action">
                              End Date:
                              <SimpleDatePicker
                                clickOnDate={this.selectDay}
                                date={this.state.endDate}
                                maxDate={new Date()}
                                name="endDate"
                              />
                            </td>
                          </tr>
                          <tr className="row row--header">
                            <td className="col col--action" />
                            <th className="col col--header">
                              <button className="button is-small" onClick={this.sortEvents('label')}>Event Label</button>
                            </th>
                            <th className="col col--header">Current Status</th>
                            <th className="col col--header" onClick={this.sortEvents('last_seen_at')}>
                              <button className="button is-small">Last Event</button>
                            </th>
                            <th className="col col--header" onClick={this.sortEvents('count')}>
                              <button className="button is-small">Event Count</button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.items.map((event) => (
                            <tr className="row row--data event-row" key={`${event.label}-${event.count > 0 ? 'active' : 'unseen'}`}>
                              <td className="col col--action">
                                <input type="checkbox" onChange={this.selectEvent(event.label)} checked={this.state.eventsSelected.includes(event.label)} />
                              </td>
                              <td className="col col--data">{event.label.replace(/^local#app#/i, '').replace(/^com.apptentive#/i, '')}</td>
                              <td className="col col--data">{event.count > 0 ? 'Active' : 'Unseen'}</td>
                              <td className="col col--data">{dateFormat(event.last_seen_at * 1000)}</td>
                              <td className="col col--data">{event.count.toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </section> : <div className="loading">Loading...</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppEventsTable;
