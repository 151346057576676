import React from 'react';
import PropTypes from 'prop-types';
import { findIndex, propEq } from 'ramda';
import gguid from './gguid';
import ComboboxOption from './combobox_option';

class Combobox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      isOpen: false,
      focusedIndex: null,
      matchedAutocompleteOption: null,
      // this prevents crazy jumpiness since we focus options on mouseenter
      usingKeyboard: false,
      listId: `token-input-list-${gguid()}`,
      menu: {
        children: [],
        activedescendant: null,
        isEmpty: true,
      },
    };

    this.inputKeydownMap = {
      8: 'removeLastToken', // delete
      13: 'selectOnEnter', // enter
      188: 'selectOnEnter', // comma
      27: 'hideOnEscape', // escape
      38: 'focusPrevious', // up arrow
      40: 'focusNext', // down arrow
    };

    this.optionKeydownMap = {
      13: 'selectOption',
      27: 'hideOnEscape',
      38: 'focusPrevious',
      40: 'focusNext',
    };

    this.clearSelectedState = this.clearSelectedState.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputClick = this.handleInputClick.bind(this);
    this.maybeShowList = this.maybeShowList.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleOptionBlur = this.handleOptionBlur.bind(this);
    this.handleOptionFocus = this.handleOptionFocus.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.showList = this.showList.bind(this);
    this.hideList = this.hideList.bind(this);
    this.hideOnEscape = this.hideOnEscape.bind(this);
    this.focusInput = this.focusInput.bind(this);
    this.selectInput = this.selectInput.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleOptionKeyDown = this.handleOptionKeyDown.bind(this);
    this.handleOptionMouseEnter = this.handleOptionMouseEnter.bind(this);
    this.selectOnEnter = this.selectOnEnter.bind(this);
    this.maybeSelectAutocompletedOption = this.maybeSelectAutocompletedOption.bind(this);
    this.selectOptionBound = this.selectOptionBound.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.selectText = this.selectText.bind(this);
    this.focusNext = this.focusNext.bind(this);
    this.removeLastToken = this.removeLastToken.bind(this);
    this.focusPrevious = this.focusPrevious.bind(this);
    this.focusSelectedOption = this.focusSelectedOption.bind(this);
    this.scanForFocusableIndex = this.scanForFocusableIndex.bind(this);
    this.focusOptionAtIndex = this.focusOptionAtIndex.bind(this);
    this.focusOption = this.focusOption.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({ menu: this.makeMenu(this.props.menuContent) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ menu: this.makeMenu(nextProps.menuContent) }, () => {
      if (nextProps.menuContent.length && (this.state.isOpen || document.activeElement === this.input)) {
        this.setState({ isOpen: true });
        this.list.scrollTop = 0;
      } else {
        this.hideList();
      }
    });
  }

  handleInputChange() {
    this.clearSelectedState(() => {
      this.props.onInput(this.input.value);
    });
  }

  handleInputFocus() {
    this.props.onFocus();
    this.maybeShowList();
  }

  handleInputClick() {
    this.maybeShowList();
  }

  handleInputBlur() {
    const focusedAnOption = this.state.focusedIndex != null;
    if (focusedAnOption) {
      return;
    }
    this.maybeSelectAutocompletedOption();
    this.hideList();
    this.props.onBlur();
  }

  handleOptionBlur() {
    // don't want to hide the list if we focused another option
    this.blurTimer = setTimeout(this.hideList, 0);
  }

  handleOptionFocus() {
    clearTimeout(this.blurTimer);
  }

  handleButtonClick() {
    if (this.state.isOpen) {
      this.hideList();
    } else {
      this.showList();
    }
    this.focusInput();
  }

  handleKeydown(event) {
    const handlerName = this.inputKeydownMap[event.keyCode];
    if (!handlerName) {
      return;
    }
    this.setState({ usingKeyboard: true });
    this[handlerName].call(this, event);
  }

  handleOptionKeyDown(child) {
    return (event) => {
      const handlerName = this.optionKeydownMap[event.keyCode];
      if (!handlerName) {
        // If we start typing again while focused on an option, move focus to the input, select so it wipes out any existing value
        this.selectInput();
        return;
      }
      event.preventDefault();
      this.setState({ usingKeyboard: true });
      this[handlerName].call(this, child);
    };
  }

  handleOptionMouseEnter(index) {
    return (_event) => {
      if (this.state.usingKeyboard) {
        this.setState({ usingKeyboard: false });
      } else {
        this.focusOptionAtIndex(index);
      }
    };
  }

  hideOnEscape(event) {
    this.hideList();
    this.focusInput();
    event.preventDefault();
  }

  focusInput() {
    this.input.focus();
  }

  selectInput() {
    this.input.select();
  }

  showList() {
    if (!this.state.menu.children.length) {
      return;
    }
    this.setState({
      isOpen: true,
    });
  }

  hideList() {
    this.setState({
      isOpen: false,
      focusedIndex: null,
    });
  }

  maybeShowList() {
    if (this.props.showListOnFocus) {
      this.showList();
    }
  }

  clearSelectedState(cb) {
    this.setState({
      focusedIndex: null,
      value: null,
      matchedAutocompleteOption: null,
    }, cb);
  }

  makeMenu(menuContent) {
    let activedescendant = null;
    const isEmpty = menuContent.length === 0;
    const children = menuContent.map((child, index) => {
      if (this.state.value === child.value) {
        activedescendant = child.value;
      }

      return (
        <ComboboxOption
          key={child.value}
          id={`token-input-selected-${gguid()}`}
          value={child.value}
          isSelected={this.state.value === child.value}
          onBlur={this.handleOptionBlur}
          onClick={this.selectOptionBound(child)}
          onFocus={this.handleOptionFocus}
          onKeyDown={this.handleOptionKeyDown(child)}
          onMouseEnter={this.handleOptionMouseEnter(index)}
        >
          {child.name}
        </ComboboxOption>
      );
    });

    return {
      children,
      activedescendant,
      isEmpty,
    };
  }

  selectOnEnter(event) {
    event.preventDefault();
    this.maybeSelectAutocompletedOption();
  }

  maybeSelectAutocompletedOption() {
    if (!this.state.matchedAutocompleteOption) {
      this.selectText();
    } else {
      this.selectOption(this.state.matchedAutocompleteOption, { focus: false });
    }
  }

  selectOptionBound(child) {
    return (event) => {
      this.selectOption(child, event);
    };
  }

  selectOption(child, optionsOrEvent = {}) {
    if (optionsOrEvent && optionsOrEvent.stopPropagation) {
      optionsOrEvent.stopPropagation();
    }

    this.setState({ matchedAutocompleteOption: null });

    this.props.onSelect(child.value, child);
    if (this.props.hideMenuOnSelect) {
      this.hideList();
      this.clearSelectedState();
      if (optionsOrEvent.focus !== false) {
        this.selectInput();
      }
      this.input.value = '';
    }
  }

  selectText() {
    if (!this.input.value) {
      return;
    }
    this.props.onSelect(this.input.value);
    this.clearSelectedState();
    this.input.value = '';
  }

  removeLastToken() {
    if (this.props.onRemoveLast && !this.input.value) {
      this.props.onRemoveLast();
    }
    return true;
  }

  focusSelectedOption() {
    const focusedIndex = findIndex(propEq('value', this.state.value))(this.props.menuContent);
    this.showList();
    this.setState({ focusedIndex }, this.focusOption);
  }

  focusNext(event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (this.state.menu.isEmpty) {
      return;
    }
    const index = this.scanForFocusableIndex(this.state.focusedIndex, 1);
    this.focusOptionAtIndex(index);
  }

  focusPrevious(event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (this.state.menu.isEmpty) {
      return;
    }
    const index = this.scanForFocusableIndex(this.state.focusedIndex, -1);
    this.focusOptionAtIndex(index);
  }

  scanForFocusableIndex(oldIndex, increment) {
    let index = oldIndex;
    if (index === null || index === undefined) {
      index = increment > 0 ? (this.props.menuContent.length - 1) : 0;
    }
    index += increment;
    if (index < 0) {
      return this.props.menuContent.length - 1;
    }
    if (index >= this.props.menuContent.length) {
      return 0;
    }
    return index;
  }

  focusOptionAtIndex(index = 0) {
    if (!this.state.isOpen && this.state.value) {
      this.focusSelectedOption();
      return;
    }
    this.showList();
    if (index < 0) {
      index = this.props.menuContent.length - 1;
    } else if (index >= this.props.menuContent.length || index === null) {
      index = 0;
    }
    this.setState({
      focusedIndex: index,
    }, this.focusOption);
  }

  focusOption() {
    const index = this.state.focusedIndex;
    if (this.list.childNodes[index]) {
      this.list.childNodes[index].focus();
    }
  }

  render() {
    return (
      <div className={`token-input ${this.state.isOpen ? 'is-open' : ''}`}>
        {this.props.value}
        <input
          ref={(d) => { this.input = d; }}
          autoComplete="off"
          spellCheck="false"
          aria-controls="expandable"
          aria-label={this.props.ariaLabel}
          aria-expanded={this.state.isOpen}
          aria-haspopup="true"
          aria-activedescendant={this.state.menu.activedescendant}
          aria-autocomplete="list"
          aria-owns={this.state.listId}
          id={this.props.id}
          disabled={this.props.isDisabled}
          className="token-input-input"
          onFocus={this.handleInputFocus}
          onClick={this.handleInputClick}
          onChange={this.handleInputChange}
          onBlur={this.handleInputBlur}
          onKeyDown={this.handleKeydown}
          placeholder={this.props.placeholder}
          role="combobox"
          type="text"
        />
        {!this.props.isDisabled ? <span aria-hidden="true" className="token-input-button" onClick={this.handleButtonClick}>▾</span> : null}
        <div id={this.state.listId} ref={(d) => { this.list = d; }} className="token-input-list" role="listbox">
          {this.state.menu.children}
        </div>
      </div>
    );
  }
}

Combobox.propTypes = {
  ariaLabel: PropTypes.string,
  hideMenuOnSelect: PropTypes.bool,
  menuContent: PropTypes.array,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  showListOnFocus: PropTypes.bool,
  value: PropTypes.string,
};

Combobox.defaultProps = {
  ariaLabel: 'Start typing to search. Use the arrow keys to navigate results. If you don\'t find an acceptable option, you can input an alternative. Once you find or input the tag you want, press Enter or Comma to add it.',
  hideMenuOnSelect: true,
  menuContent: [],
  onBlur: () => {},
  onFocus: () => {},
  onInput: () => {},
  onSelect: () => {},
  placeholder: null,
  showListOnFocus: false,
  value: null,
};

export default Combobox;
