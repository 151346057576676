import React from 'react';
import PropTypes from 'prop-types';

const ComboboxOption = (props, _context) => {
  const {
    className,
    isSelected,
    value,
    ...rest
  } = props;

  return (
    <div className={`${className} ${isSelected ? 'token-input-option-selected' : ''}`} aria-selected={isSelected} {...rest}>
      {value}
    </div>
  );
};

ComboboxOption.propTypes = {
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  role: PropTypes.string,
  tabIndex: PropTypes.string,
  value: PropTypes.any.isRequired,
};

ComboboxOption.defaultProps = {
  role: 'option',
  tabIndex: '-1',
  className: 'token-input-option',
  isSelected: false,
};

export default ComboboxOption;
