function isObject(mods) {
  return !Array.isArray(mods) && typeof mods === 'object';
}

function resolveMods(mods) {
  if (isObject(mods)) {
    return Object.keys(mods).reduce((res, mod) => (mods[mod] ? [...res, mod] : res), []);
  }
  return Array.isArray(mods) ? mods : [mods];
}

export const bemPrefix = (classNamePrefix) => (className, modificators) => {
  const coreClass = className ? [classNamePrefix, className].join('__') : classNamePrefix;
  const classNames = [coreClass];
  const mods = resolveMods(modificators);

  if (mods.length) {
    mods.forEach((mod) => mod && classNames.push(`${coreClass}--${mod}`));
  }

  return classNames.join(' ');
};
