import React from 'react';
import onClickOutside from 'react-onclickoutside';
import Mousetrap from 'mousetrap';
import { bemPrefix } from '../../../utils/bem';
import { MODAL_TYPE } from '../gdpr-types';

import './gdpr-modal.scss';

const bem = bemPrefix('gdpr-modal');

export class GdprModal extends React.PureComponent {
  mousetrap = new Mousetrap();

  componentDidMount() {
    this.mousetrap.bind(['esc'], this.handleClickOutside);
  }

  componentWillUnmount() {
    this.mousetrap.unbind(['esc']);
  }

  handleClickOutside = (event) => {
    if (['keydown', 'keypress'].includes(event.type) && event.key === 'Escape') {
      this.props.cancelHandler(event);
      return;
    }
    if (this.props.targetEl && this.props.targetEl.contains(event.target)) {
      return;
    }
    this.props.cancelHandler(event);
  };

  render() {
    const { submitLabel, submitHandler, cancelHandler, shownModal } = this.props;
    return (
      <div className={bem()}>
        <div className={bem('body')}>
          <p className={bem('body__title')}>
            {shownModal === MODAL_TYPE.erasure ? 'WARNING!' : 'Remove this request from the list?'}
          </p>
          <div className={bem('body__text')}>
            <span>
              {shownModal === MODAL_TYPE.erasure
                ? 'After the data is erased it can’t be recovered. Proceeed with this action?'
                : 'This action can’t be undone.'}
            </span>
          </div>
        </div>
        <div className={bem('footer')}>
          <button className={bem('footer-delete')} onClick={submitHandler}>
            {submitLabel}
          </button>
          <button className={bem('footer-cancel')} onClick={cancelHandler}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

export const GdprModalContainer = onClickOutside(GdprModal);
