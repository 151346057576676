import React from 'react';
import dayjs from 'dayjs';

export const fullFormat = (date) => dayjs(date).format('dddd MMMM D YYYY H:mm:ssA Z');

const dateFormat = (date, format = 'YYYY-MM-DD') => {
  if (!date) {
    return null;
  }

  return (
    <span title={fullFormat(date)}>
      {dayjs(date).format(format)}
    </span>
  );
};

export default dateFormat;
