import React, { Component } from 'react';
import { sortBy, prop, reverse } from 'ramda';
import dateFormat from 'utils/date_format';

class IosAppReleaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.list || [],
      sortKey: 'cf_bundle_version',
      sortReverse: false,
    };

    this.sortByKey = this.sortByKey.bind(this);
  }

  sortByKey(sortKey) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      let sortReverse = false;
      let data = sortBy(prop(sortKey), this.state.data);
      if (sortKey === this.state.sortKey && this.state.sortReverse === false) {
        sortReverse = true;
        data = reverse(data);
      }
      this.setState({ sortKey, data, sortReverse });
    };
  }

  render() {
    return (
      <table className="table is-fullwidth is-striped is-bordered">
        <thead>
          <tr>
            <th onClick={this.sortByKey('created_at')}>Created At</th>
            <th onClick={this.sortByKey('debug')}>Debug Build</th>
            <th onClick={this.sortByKey('cf_bundle_version')}>CFBundleVersion</th>
            <th onClick={this.sortByKey('cf_bundle_short_version_string')}>CFBundleShortVersionString</th>
            <th onClick={this.sortByKey('overriding_styles')}>Overriding Styles?</th>
            <th onClick={this.sortByKey('has_app_store_receipt')}>Has App Store Receipt?</th>
            <th onClick={this.sortByKey('sdk_version')}>SDK Version</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.map((release) => (
            <tr className="ios-release-row" key={`${release.identifier}-${release.version_name}-${release.created_at}`}>
              <td>{dateFormat(release.created_at)}</td>
              <td>{release.debug ? release.debug.toString() : 'false'}</td>
              <td>{release.cf_bundle_version}</td>
              <td>{release.cf_bundle_short_version_string}</td>
              <td>{release.overriding_styles ? release.overriding_styles.toString() : 'false'}</td>
              <td>{release.has_app_store_receipt ? release.has_app_store_receipt.toString() : 'false'}</td>
              <td>{release.sdk_version}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default IosAppReleaseList;
