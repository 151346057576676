import React from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'utils/date_format';
import AppMembershipsList from './app_memberships_list';
import AuthorizationsList from './authorizations_list';

const UserDetails = ({ user }) => (
  <div>
    <div className="columns">
      <div className="column">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">User Details</p>
          </header>
          <div className="card-table">
            <div className="content">
              <table className="table is-fullwidth is-striped is-bordered">
                <tbody>
                  <tr>
                    <td>Name:</td>
                    <td>{user.name}</td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>{user.email}</td>
                  </tr>
                  <tr>
                    <td>Admin:</td>
                    <td>{user.admin ? user.admin.toString() : 'false'}</td>
                  </tr>
                  <tr>
                    <td>Company Telephone:</td>
                    <td>{user.company_telephone}</td>
                  </tr>
                  <tr>
                    <td>Professional Role:</td>
                    <td>{user.professional_role}</td>
                  </tr>
                  <tr>
                    <td>Last Sign-in At:</td>
                    <td>{dateFormat(user.current_sign_in_at)}</td>
                  </tr>
                  <tr>
                    <td>Invitation Created At:</td>
                    <td>{dateFormat(user.invitation_created_at)}</td>
                  </tr>
                  <tr>
                    <td>Invitation Sent At:</td>
                    <td>{dateFormat(user.invitation_sent_at)}</td>
                  </tr>
                  <tr>
                    <td>Invitation Accepted At:</td>
                    <td>{dateFormat(user.invitation_accepted_at)}</td>
                  </tr>
                  <tr>
                    <td>Invited By:</td>
                    <td>{user.invited_by ? <Link to={`/users/${user.invited_by.id}`}>{user.invited_by.name} ({user.invited_by.email})</Link> : '' }</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">Meta</p>
          </header>
          <div className="card-table">
            <div className="content">
              <table className="table is-fullwidth is-striped is-bordered">
                <tbody>
                  <tr>
                    <td>Created At:</td>
                    <td>{dateFormat(user.created_at)}</td>
                  </tr>
                  <tr>
                    <td>Updated At:</td>
                    <td>{dateFormat(user.updated_at)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">App Memberships</p>
          </header>
          <div className="card-table">
            <div className="content">
              <AppMembershipsList id={user.id} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AuthorizationsList userId={user.id} />
  </div>
);

UserDetails.defaultProps = {
  user: {
    admin: false,
    invited_by: {},
  },
};

export default UserDetails;
