import { fetchCurrentUser, get, post } from '../../utils/api';
import { atriumBaseUrl } from '../../resources';

const gdprApi = {
  findOrDeletePD: async (payload) => {
    let reqBody = {};

    if (payload.id) {
      reqBody = {
        subject_request_id: payload.id,
        submitted_time: payload.date,
        subject_request_type: payload.requestType,
        subject_identities: [],
        api_version: '2.0',
        extensions: payload.extensions,
      };
    } else {
      const { name } = await fetchCurrentUser();

      reqBody = {
        submitted_time: new Date().toISOString(),
        subject_request_type: payload.requestType,
        subject_identities: [],
        api_version: '2.0',
        extensions: {
          'apptentive.com': {
            org_id: payload.orgId,
            identity_type: payload.identityType,
            identity_value: payload.identityValue,
            created_by: name,
            description: payload.description,
          },
        },
      };
    }

    const response = await post(`${atriumBaseUrl}/pdrs/requests`, reqBody);

    return response.json();
  },

  fetchRequests: async ({ sortBy = 'date', order = 'desc' }) => {
    const response = await get(`${atriumBaseUrl}/pdrs/private/requests?sort_by=${sortBy}&order=${order}`);
    return response.json();
  },

  removeRequest: async (id) => {
    const { name } = await fetchCurrentUser();

    const response = await post(`${atriumBaseUrl}/pdrs/private/delete-request`, { id, deleted_by: name });
    return response.json();
  },
};

export default gdprApi;
