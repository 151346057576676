import fetch from 'isomorphic-fetch';
import { currentUser } from 'resources';
import SavedLocation from 'utils/saved_location';
import JWT from 'utils/jwt';

const defaultHeaders = () => ({
  'Content-Type': 'application/json',
  authorization: `Bearer ${JWT.getToken()}`,
  Accept: 'application/json',
});

const call = async (method, url, headers, rawBody) => {
  if (!JWT.valid()) {
    SavedLocation.set(window.location.pathname);
  }

  const body = rawBody && JSON.stringify(rawBody);
  const response = await fetch(url, {
    method,
    headers,
    body,
  });
  return response;
};

export const get = (url, headers = defaultHeaders()) => call('GET', url, headers);
export const del = (url, headers = defaultHeaders()) => call('DELETE', url, headers);
export const put = (url, body, headers = defaultHeaders()) => call('PUT', url, headers, body);
export const post = (url, body, headers = defaultHeaders()) => call('POST', url, headers, body);

export const fetchCurrentUser = async () => {
  const token = JWT.getToken();
  if (token) {
    const response = await get(currentUser.url);
    switch (response.status) {
      case 500: {
        return Promise.reject(new Error(response.json()));
      }
      case 401: {
        JWT.clear();
        window.location.assign('/loading');
        return Promise.reject(new Error('Unauthorized'));
      }
      default:
        return response.json();
    }
  }
  return Promise.reject(new Error('Unauthenticated'));
};
