import React, { Component } from 'react';
import { adminBaseUrl } from 'resources/index';
import * as api from 'utils/api';
import Loading from 'shared/loading';
import Notification from 'shared/notification';
import dateFormat from 'utils/date_format';
import {
  __,
  append,
  contains,
  ifElse,
  without
} from 'ramda';

class OrganizationAppMigrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedApps: [],
      foundOrg: null,
      errors: [],
      successes: [],
      hasFetched: false,
    };

    this.fetch = this.fetch.bind(this);
    this.dismissErrors = this.dismissErrors.bind(this);
  }

  componentDidMount() {
    document.title = `Editing ${this.props.organization.name}`;
    this.fetch(this.props.organization.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.organization.id && !this.state.hasFetched) {
      this.fetch(nextProps.organization.id);
    }
  }

  async fetch(id) {
    try {
      const response = await api.get(
        `${adminBaseUrl}/organizations/${id}/apps`
      );
      const data = await response.json();
      if (data && data.apps) {
        this.setState({
          data: data.apps,
          hasFetched: true,
        });
      } else if (data && data.error) {
        this.setState({
          errors: [
            `Failed to fetch Organization apps: ${data.error}`,
            ...this.state.errors,
          ],
          hasFetched: true,
        });
      }
    } catch (error) {
      this.setState({
        errors: [
          `Failed to fetch Organization apps: ${error}`,
          ...this.state.errors,
        ],
        hasFetched: true,
      });
    }
  }

  async findOrg(orgId) {
    // Clear current selection
    this.setState({
      foundOrg: null,
    });
    try {
      const response = await api.get(`${adminBaseUrl}/organizations/${orgId}`);
      const data = await response.json();
      if (data && data.id) {
        this.setState({
          foundOrg: data,
        });
      } else if (data && data.error) {
        console.error(data.error);
      }
    } catch (error) {
      this.setState({
        errors: [`Failed to find Organization: ${error}`, ...this.state.errors],
        hasFetched: true,
      });
    }
  }

  dismissErrors(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      errors: [],
    });
  }

  dismissSuccesses(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      successes: 0,
    });
  }

  selectApp(appId) {
    const toggleApp = ifElse(
      contains(__, this.state.selectedApps),
      without(__, this.state.selectedApps),
      append(__, this.state.selectedApps)
    );
    return () => {
      if (appId) {
        this.setState({
          selectedApps: toggleApp(appId),
        });
      }
    };
  }

  async migrateApp(appID, migrationResults) {
    const { errors, successes } = migrationResults;
    const response = await api.post(
      `${adminBaseUrl}/apps/${appID}/switch_orgs`,
      {
        app_id: appID,
        organization_id: this.state.foundOrg.id,
      }
    );
    if (!response.ok) {
      try {
        const json = await response.json();
        errors.push(
          `${appID} migration failed: ${json.error ||
            'unknown reasons'}`
        );
      } catch (e) {
        errors.push(`${appID} migration failed for unknown reasons`);
      }
    } else {
      successes.push(`${appID} migration succeeded`);
    }
  }

  async migrateApps() {
    const { selectedApps, foundOrg } = this.state;
    const migrationResults = {
      errors: [],
      successes: [],
    };
    try {
      if (
        window.confirm(
          `Are you sure you want to migrate ${selectedApps.length} apps from ${
            this.props.organization.name
          } to ${foundOrg.name}?`
        )
      ) {
        this.setState({
          hasFetched: false,
        });
        await Promise.all(
          selectedApps.map(appID => this.migrateApp(appID, migrationResults))
        );
      }
    } catch (e) {
      migrationResults.errors.push(`Migration failed for unknown reasons: ${e}`);
    }
    await this.fetch(this.props.organization.id);
    this.setState({
      errors: migrationResults.errors,
      successes: migrationResults.successes,
    });
  }

  renderAppRow(app) {
    return (
      <tr key={app.id}>
        <td>{app.title}</td>
        <td>{app.id}</td>
        <td>{app && app.mau_count && app.mau_count.toLocaleString()}</td>
        <td>{app.platform}</td>
        <td>
          {app.store_id} {app.platform}
        </td>
        <td>{app.created_at}</td>
        <td>{app.went_live_on}</td>
        <td>
          <input
            type="checkbox"
            onChange={this.selectApp(app.id)}
            checked={this.state.selectedApps.includes(app.id)}
          />
        </td>
      </tr>
    );
  }

  render() {
    const { foundOrg } = this.state;
    return this.state.hasFetched ? (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">App Migration</p>
            </header>
            <div className="card-content">
              <div className="content">
                {this.state.errors.length ? (
                  <Notification
                    type="danger"
                    content={this.state.errors}
                    dismiss={this.dismissErrors}
                  />
                ) : null}
                {this.state.successes.length ? (
                  <Notification
                    type="success"
                    content={this.state.successes}
                    dismiss={this.dismissSuccesses}
                  />
                ) : null}
                <table className="table is-fullwidth is-striped is-bordered app-list">
                  <thead>
                    <tr>
                      <th className="sort-by">Name</th>
                      <th className="sort-by">ID</th>
                      <th className="sort-by">MAU</th>
                      <th className="sort-by">Platform</th>
                      <th className="sort-by">Store ID</th>
                      <th className="sort-by">Created</th>
                      <th className="sort-by">Went Live</th>
                      <th>Migrate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data && this.state.data.map(app => this.renderAppRow(app))}
                  </tbody>
                </table>
                <div className="field">
                  <label className="label" htmlFor="name">
                    Target Organization ID
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder=" Org ID e.g 534...."
                        name="name"
                        required
                        onChange={e => this.findOrg(e.target.value)}
                      />
                    </div>
                  </label>
                </div>
                {foundOrg && (
                  <table className="table is-fullwidth is-striped is-bordered app-list">
                    <thead>
                      <tr>
                        <th className="sort-by">ID</th>
                        <th className="sort-by">Name</th>
                        <th className="sort-by">MAU</th>
                        <th className="sort-by">Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{foundOrg.id}</td>
                        <td>{foundOrg.name}</td>
                        <td>
                          {foundOrg.mau_count &&
                            foundOrg.mau_count.toLocaleString()}
                        </td>
                        <td>{dateFormat(foundOrg.created_at)}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <button
                disabled={
                  !(this.state.foundOrg && this.state.selectedApps.length > 0)
                }
                className="button is-link"
                onClick={() => this.migrateApps()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Loading text="Loading App Migration Form..." />
    );
  }
}

OrganizationAppMigrationForm.defaultProps = {
  organization: {
    name: '',
    id: '',
  },
};

export default OrganizationAppMigrationForm;
