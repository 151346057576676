import React, { Component } from 'react';
import * as api from 'utils/api';
import AndroidAppReleaseList from 'components/apps/android_app_release_list';
import IosAppReleaseList from 'components/apps/ios_app_release_list';
import { adminBaseUrl } from 'resources';

class AppReleaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFetched: false,
      data: [],
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    if (this.props.app && this.props.app.id) {
      this.fetch(this.props.app.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.app && nextProps.app.id && !this.state.hasFetched) {
      this.fetch(nextProps.app.id);
    }
  }

  async fetch(id) {
    const response = await api.get(`${adminBaseUrl}/apps/${id}/app_releases`);
    const data = await response.json();
    this.setState({
      data,
      hasFetched: true,
    });
  }

  render() {
    let contents = null;
    if (!this.state.hasFetched) {
      contents = <div>Loading...</div>;
    } else if (this.props.app.platform === 'iOS') {
      contents = <IosAppReleaseList list={this.state.data} />;
    } else if (this.props.app.platform === 'Android') {
      contents = <AndroidAppReleaseList list={this.state.data} />;
    } else if (this.props.app.platform === 'Web') {
      return null;
    }

    return (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">App Releases</p>
            </header>
            <div className="card-table">
              <div className="content">
                {contents}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppReleaseList;
