import React, { Component } from 'react';
import { sortBy, prop, reverse } from 'ramda';
import dateFormat from 'utils/date_format';

class AndroidAppReleaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.list || [],
      sortKey: 'version_name',
      sortReverse: false,
    };

    this.sortByKey = this.sortByKey.bind(this);
  }

  sortByKey(sortKey) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      let sortReverse = false;
      let data = sortBy(prop(sortKey), this.state.data);
      if (sortKey === this.state.sortKey && this.state.sortReverse === false) {
        sortReverse = true;
        data = reverse(data);
      }
      this.setState({ sortKey, data, sortReverse });
    };
  }

  render() {
    return (
      <table className="table is-fullwidth is-striped is-bordered">
        <thead>
          <tr>
            <th onClick={this.sortByKey('created_at')}>Created At</th>
            <th onClick={this.sortByKey('debug')}>Debug Build</th>
            <th onClick={this.sortByKey('version_name')}>Version Name</th>
            <th onClick={this.sortByKey('version_code')}>Version Code</th>
            <th onClick={this.sortByKey('identifier')}>Identifier</th>
            <th onClick={this.sortByKey('target_sdk_version')}>Target SDK Version</th>
            <th onClick={this.sortByKey('app_store')}>App Store</th>
            <th onClick={this.sortByKey('inheriting_styles')}>Inheriting Styles?</th>
            <th onClick={this.sortByKey('overriding_styles')}>Overriding Styles?</th>
            <th onClick={this.sortByKey('sdk_version')}>SDK Version</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.map((release) => (
            <tr className="android-release-row" key={`${release.identifier}-${release.version_name}-${release.created_at}`} release={release}>
              <td>{dateFormat(release.created_at)}</td>
              <td>{release.debug ? release.debug.toString() : 'false'}</td>
              <td>{release.version_name}</td>
              <td>{release.version_code}</td>
              <td>{release.identifier}</td>
              <td>{release.target_sdk_version}</td>
              <td>{release.app_store}</td>
              <td>{release.inheriting_styles ? release.inheriting_styles.toString() : 'false'}</td>
              <td>{release.overriding_styles ? release.overriding_styles.toString() : 'false'}</td>
              <td>{release.sdk_version}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default AndroidAppReleaseList;
