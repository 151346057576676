import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import JWT from 'utils/jwt';
import Login from 'components/login';
import Loading from 'components/loading';
import AdminNav from 'components/admin_nav';
import AppShow from 'components/apps/app_show';
import AppInteractions from 'components/apps/app_interactions';
import AppInteractionShow from 'components/apps/app_interactions_show';
import AppSettings from 'components/apps/app_settings';
import UserShow from 'components/users/user_show';
import RegisterUser from 'components/users/register_user';
import { GdprPageContainer } from 'components/gdpr/gdpr';
import OrganizationShow from 'components/organizations/organization_show';
import OrganizationEdit from 'components/organizations/organization_edit';
import OrganizationAppMigration from 'components/organizations/organization_app_migration';
import EntitlementEdit from 'components/organizations/entitlement_edit';
import EntitlementView from 'components/organizations/entitlement_view';
import EntitlementNew from 'components/organizations/entitlement_new';
import Search from 'components/shared/search';
import AppStoreImporter from 'components/shared/app_store_importer';
import { RolloutPageContainer } from 'components/rollout/rollout';
import KeysPageContainer from 'components/keys/keys';

import './styles/styles.scss';

const logout = () => <div>LOGGED OUT</div>;

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (JWT.valid() ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />)}
  /> // eslint-disable-line
);

const app = (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={logout} />
      <Route exact path="/loading" component={Loading} />
      <>
        <AdminNav />
        <PrivateRoute exact path="/" component={Search} />
        <PrivateRoute exact path="/apps" component={Search} />
        <PrivateRoute exact path="/apps/:id" component={AppShow} />
        <PrivateRoute exact path="/apps/:id/interactions" component={AppInteractions} />
        <PrivateRoute exact path="/apps/:id/interactions/:interaction_id" component={AppInteractionShow} />
        <PrivateRoute exact path="/apps/:id/settings" component={AppSettings} />
        <PrivateRoute exact path="/users" component={Search} />
        <PrivateRoute exact path="/users/:id" component={UserShow} />
        <PrivateRoute exact path="/organizations" component={Search} />
        <PrivateRoute exact path="/organizations/:id" component={OrganizationShow} />
        <PrivateRoute exact path="/organizations/:id/edit" component={OrganizationEdit} />
        <PrivateRoute exact path="/organizations/:id/app-migration" component={OrganizationAppMigration} />
        <PrivateRoute exact path="/organizations/:id/new-entitlement" component={EntitlementNew} />
        <PrivateRoute exact path="/organizations/:id/entitlement/:entitlement_id" component={EntitlementView} />
        <PrivateRoute exact path="/organizations/:id/entitlement/:entitlement_id/edit" component={EntitlementEdit} />
        <PrivateRoute exact path="/import-app" component={AppStoreImporter} />
        <PrivateRoute exact path="/register-user" component={RegisterUser} />
        <PrivateRoute exact path="/data-management" component={GdprPageContainer} />
        <PrivateRoute exact path="/keys" component={KeysPageContainer} />
        <PrivateRoute exact path="/rollout" component={RolloutPageContainer} />
      </>
    </Switch>
  </BrowserRouter>
);

ReactDom.render(app, document.getElementById('app'));

export default app;
