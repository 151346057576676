import { get, post, put } from '../../utils/api';
import { atriumBaseUrl } from '../../resources';

const rolloutApi = {
  fetchFeatureFlags: async () => {
    const response = await get(`${atriumBaseUrl}/apptentive_admin/feature_flags`);
    return response.json();
  },

  fetchFeatureSettings: async (feature) => {
    const response = await get(`${atriumBaseUrl}/apptentive_admin/feature_flags/${feature}`);
    return response.json();
  },

  updateFeatureSettings: async (settings) => {
    const response = await put(`${atriumBaseUrl}/apptentive_admin/feature_flags/${settings.feature}`, settings);
    return response.json();
  },

  createFeatureFlag: (payload) => new Promise((res, rej) =>
    post(`${atriumBaseUrl}/apptentive_admin/feature_flags`, { feature: payload.feature })
      .then(() => put(`${atriumBaseUrl}/apptentive_admin/feature_flags/${payload.feature}`, payload))
      .then(() => res('OK'))
      .catch(e => rej(e))),
};

export default rolloutApi;
