import React, { Component } from 'react';
import { isEmpty, sortBy, prop, reject, reverse } from 'ramda';
import * as api from 'utils/api';
import dateFormat from 'utils/date_format';
import { adminBaseUrl } from 'resources';

class AppSdkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      hasFetched: false,
      sortKey: 'version',
      sortReverse: false,
    };

    this.fetch = this.fetch.bind(this);
    this.sortByKey = this.sortByKey.bind(this);
  }

  componentDidMount() {
    if (this.props.app && this.props.app.id) {
      this.fetch(this.props.app.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.app && nextProps.app.id && !this.state.hasFetched) {
      this.fetch(nextProps.app.id);
    }
  }

  async fetch(id) {
    const response = await api.get(`${adminBaseUrl}/apps/${id}/sdks`);
    const data = await response.json();
    this.setState({
      data,
      hasFetched: true,
    });
  }

  sortByKey(sortKey) {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      let sortReverse = false;
      let data = sortBy(prop(sortKey), this.state.data);
      if (sortKey === this.state.sortKey && this.state.sortReverse === false) {
        sortReverse = true;
        data = reverse(data);
      }
      this.setState({ sortKey, data, sortReverse });
    };
  }

  render() {
    return (
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">SDKs</p>
            </header>
            <div className="card-table">
              <div className="content">
                <table className="table is-fullwidth is-striped is-bordered">
                  <thead>
                    <tr>
                      <th className="sort-by" onClick={this.sortByKey('version')}>Version</th>
                      <th className="sort-by" onClick={this.sortByKey('programming_language')}>Programming Language</th>
                      <th className="sort-by" onClick={this.sortByKey('distribution')}>Distribution</th>
                      <th className="sort-by" onClick={this.sortByKey('distribution_version')}>Distribution Version</th>
                      <th className="sort-by" onClick={this.sortByKey('created_at')}>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reject(isEmpty, this.state.data).map((sdk) => (
                      <tr className="sdk-row" key={`${sdk.version}-${sdk.created_at}`}>
                        <td>{sdk.version}</td>
                        <td>{sdk.programming_language}</td>
                        <td>{sdk.distribution}</td>
                        <td>{sdk.distribution_version}</td>
                        <td>{dateFormat(sdk.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppSdkList;
