import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as api from 'utils/api';
import { adminBaseUrl } from 'resources';
import Loading from 'shared/loading';
import Notification from 'shared/notification';
import OrganizationDetails from 'components/organizations/organization_details';
import CollaboratorsList from './collaborators_list';
import AppsList from './apps_list';
import EntitlementList from './entitlement_list';

class OrganizationShow extends Component {
  constructor(params) {
    super(params);
    this.state = {
      data: {
        name: '',
        plan: {},
        cs_manager: {},
        billing_contact: {},
        limit_interactions: false,
        obfuscation_enabled: '',
      },
      errors: [],
      hasFetched: false,
    };

    this.fetch = this.fetch.bind(this);
    this.dismissErrors = this.dismissErrors.bind(this);
    this.favorite = this.favorite.bind(this);
  }

  componentDidMount() {
    document.title = 'Organization Show';
    this.fetch(this.props.match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.match.params.id && this.state.hasFetched) {
      this.fetch(nextProps.match.params.id);
    }
  }

  async fetch(id) {
    try {
      const response = await api.get(`${adminBaseUrl}/organizations/${id}`);
      const data = await response.json();
      if (data && data.error) {
        this.setState({
          errors: [`Failed to fetch Org: ${data.error}`, ...this.state.errors],
          hasFetched: false,
        });
      } else if (data) {
        this.setState({
          data,
          hasFetched: true,
        });
      }
    } catch (error) {
      this.setState({
        errors: [`Failed to fetch Org: ${error}`, ...this.state.errors],
        hasFetched: false,
      });
    }
  }

  dismissErrors(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      errors: [],
    });
  }

  favorite(id) {
    return (_event) => {
      api.post(`${adminBaseUrl}/favorites?id=${id}&type=organizations`);
    };
  }

  render() {
    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="is-active">
                  <Link to={`/organizations/${this.props.match.params.id}`} aria-current="page">Org: {this.props.match.params.id}</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="column">
            <nav className="breadcrumb has-no-separator is-right">
              <ul>
                <li>
                  <Link to={`/organizations/${this.props.match.params.id}/new-entitlement`}>
                    <button className="button is-small" type="button">New Entitlement</button>
                  </Link>
                </li>
                <li>
                  <Link to={`/organizations/${this.props.match.params.id}/edit`}>
                    <button className="button is-small" type="button">Edit Org</button>
                  </Link>
                </li>
                <li>
                  <Link to={`/organizations/${this.props.match.params.id}/app-migration`}>
                    <button className="button is-small" type="button">App Migration</button>
                  </Link>
                </li>
                <li><button className="button is-small" type="button" onClick={this.favorite(this.props.match.params.id)}>Favorite</button></li>
              </ul>
            </nav>
          </div>
        </div>
        {
          this.state.errors.length ?
            <div className="columns">
              <div className="column">
                {this.state.errors.length ? <Notification type="danger" content={this.state.errors} dismiss={this.dismissErrors} /> : null}
              </div>
            </div> : null
        }
        {
          this.state.hasFetched ?
            <div>
              <OrganizationDetails key={`${this.state.data.id}-OrganizationDetails`} organization={this.state.data} />
              <CollaboratorsList key={`${this.state.data.id}-CollaboratorsList`} id={this.state.data.id} />
              <AppsList key={`${this.state.data.id}-AppsList`} id={this.state.data.id} />
              <EntitlementList key={`${this.state.data.id}-EntitlementList`} id={this.state.data.id} />
            </div> : <Loading text="Loading..." />
        }
      </div>
    );
  }
}

export default OrganizationShow;
