import React from 'react';

const Loading = ({ text = 'Loading' }) => (
  <div className="simple-loader">
    <h1>{text}</h1>
    <span />
    <span />
    <span />
  </div>
);

export default Loading;
